import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "./../Api";
import {
  Form,
  Input,
  Button,
  Result,
  Row,
  Divider,
  Spin,
  List,
  InputNumber,
  Layout,
  Typography,
} from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { ProductName } from "./../Common";
import { useTranslation } from "react-i18next";
import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";

const { Content } = Layout;
const { TextArea } = Input;
const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const Order = () => {
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [byOrderExist, setByOrderExist] = useState(false);
  const [products, setProducts] = useState([]);
  const [cartData, setCartData] = useState([]);

  const history = useHistory();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const getCartData = async () => {
      await api
        .cart()
        .get()
        .then((response) => setCartData(response.data));
    };
    const getProducts = async () => {
      await api
        .cartProducts()
        .get()
        .then((response) => setProducts(response.data));
    };
    getProducts();
    getCartData();
  }, []);

  useEffect(() => {
    if (cartData) {
      if (cartData.filter((x) => x.price === 0).length > 0 || total !== 0) {
        setDisabled(false);
      }
      if (cartData.filter((x) => x.price === 0).length === 0 && total === 0) {
        setDisabled(true);
      }
      setTotal(
        Number(
          cartData
            .reduce((prev, cur) => prev + cur.price * cur.quantity, 0)
            .toFixed(2)
        )
      );
      if (cartData.filter((x) => x.price === 0).length > 0) {
        setByOrderExist(true);
      } else {
        setByOrderExist(false);
      }
    }
  }, [cartData, total]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCartDelete = async (id, index) => {
    cartData.splice(index, 1);
    setCartData((cartData) => [...cartData]);

    await api.deleteRowCart(id).delete();
    if (cartData.filter((x) => x.price === 0).length > 0) {
      setByOrderExist(true);
    } else {
      setByOrderExist(false);
    }
  };

  const onQuantityChange = (value, productId) => {
    const _tempRows = [...cartData];
    _tempRows.find((x) => x.productId === productId).quantity = value;
    setCartData(_tempRows);
    if (cartData.filter((x) => x.price === 0).length > 0) {
      setByOrderExist(true);
    } else {
      setByOrderExist(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    let uid = "";
    if (Cookie.get("jwtToken")) {
      let decoded = jwt_decode(Cookie.get("jwtToken"));
      uid = decoded.id;
    }

    if (uid !== "") {
      let sendMailObj = {};
      let readyToSend = false;
      const documentData = {
        customerUid: uid,
        notes: values.notes ? values.notes : "",
        type: 1,
        paymentStatus: "pending",
        fulfillmentStatus: "pending",
        totalSum: total,
      };
      await api
        .documents()
        .post(documentData)
        .then(async (response) => {
          const ordersBody = [];
          cartData.forEach((row) => {
            ordersBody.push({
              orderUid: response.data.id,
              productId: row.productId,
              quantity: row.quantity,
              price: row.price,
            });
          });
          await api
            .orders()
            .post(ordersBody)
            .catch((error) => console.log(error));
          sendMailObj = response.data;
        })
        .finally((readyToSend = true))
        .catch((error) => console.log(error));
      if (readyToSend) {
        await api.cart().delete();
        await api
          .sendMail(localStorage.getItem("i18nextLng"))
          .post(sendMailObj)
          .catch((err) => console.log(err));
        await api
          .informations()
          .get()
          .then(async (response) => {
            if (response.data[0].sendAutoToAxonaut) {
              await api
                .sendInvoice()
                .post(sendMailObj)
                .then((res) => console.log(res.data))
                .catch((err) => console.log(err));
            }
          });
      }
    }
    setResult(true);
    setCartData([]);
    setLoading(false);
  };

  const handleAlreadyOrdered = () => {
    if (cartData && cartData.length > 0) {
      cartData.forEach(async (data) => {
        await api
          .addToCart()
          .put(data)
          .then()
          .catch((err) => console.log(err));
      });
    }
    history.push("/");
    window.location.reload();
  };

  return (
    <Spin
      indicator={
        <LoadingOutlined style={{ fontSize: 24, color: "#2bbef9" }} spin />
      }
      spinning={loading}
    >
      <Layout>
        <Content
          style={{
            margin: "auto",
            padding: 24,
            background: "#fff",
            minHeight: "100vh",
            borderRadius: 12,
            width: "100%",
          }}
        >
          <Title>{t("Cart.CartTitle")}</Title>
          <List
            itemLayout="horizontal"
            dataSource={cartData}
            renderItem={(item, index) => (
              <List.Item
                actions={[
                  <InputNumber
                    min={1}
                    max={999}
                    name="quantity"
                    onChange={(value) =>
                      onQuantityChange(value, item.productId)
                    }
                    value={item.quantity}
                  />,
                  <Button
                    type="link"
                    onClick={() => handleCartDelete(item.id, index)}
                    style={{ marginLeft: 10 }}
                  >
                    <DeleteOutlined />
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={
                    products.length > 0 ? (
                      <Link
                        to={
                          "product/" +
                          products.find((x) => x.id === item.productId).slug
                        }
                      >
                        <div>
                          {ProductName(
                            products.find((x) => x.id === item.productId),
                            i18n.language
                          )}
                        </div>
                      </Link>
                    ) : (
                      ""
                    )
                  }
                />
                <div className="cartPrice">
                  {item.price !== 0 ? item.price + " €" : t("Cart.ByOrder")}
                </div>
              </List.Item>
            )}
          />
          {!result ? (
            <>
              <Divider dashed />
              <div className="totalPrice">
                {t("Cart.Total")}: {total} €{" "}
                {byOrderExist ? <span>*</span> : ""}
              </div>
              <div className="additionalText">
                {byOrderExist ? "* " + t("Cart.AdditionalText") : ""}
              </div>
              <Divider dashed />
              <Form
                {...formItemLayout}
                onFinish={handleSubmit}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item label={t("Order.OrderNotes")} name="notes">
                  <TextArea
                    disabled={disabled}
                    type="text"
                    autoSize={{ minRows: 3, maxRows: 50 }}
                  />
                </Form.Item>
                <Divider dashed />
                <Row type="flex" justify="center" align="middle">
                  <Form.Item>
                    <Button
                      disabled={disabled}
                      type="primary"
                      htmlType="submit"
                    >
                      {t("Order.OrderNow")}
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="button"
                      style={{ margin: "0 8px" }}
                      onClick={handleAlreadyOrdered}
                    >
                      {t("Order.Back")}
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </>
          ) : (
            <Result
              status="success"
              title={t("Order.ThankYou")}
              subTitle={t("Order.ThankYou2")}
              extra={[
                <Button
                  onClick={handleAlreadyOrdered}
                  type="primary"
                  key="console"
                >
                  {t("Order.Back")}
                </Button>,
              ]}
            />
          )}
        </Content>
      </Layout>
    </Spin>
  );
};
export default Order;
