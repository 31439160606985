import React from "react";
import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import { Row, Col } from "antd";

export const GetPrices = (productId, prices) => {
  if (prices.some((x) => x.productId === productId)) {
    return (
      prices.find((x) => parseInt(x.productId) === parseInt(productId)).price +
      " €"
    );
  }
  return "";
};

export const GetDiscount = (productId, prices) => {
  if (
    prices.some((x) => x.productId === productId) &&
    prices.find((x) => x.productId === productId).discount &&
    prices.find((x) => x.productId === productId).discount > 0
  ) {
    return (
      <div className="discount">
        {Number(
          prices.find((x) => parseInt(x.productId) === parseInt(productId))
            .discount
        ).toString() + " %"}
      </div>
    );
  }
  return "";
};

export const GetDiscountedPrice = (
  productId,
  prices,
  t,
  fromProductPage = false
) => {
  if (
    prices.some((x) => x.productId === productId) &&
    prices.find((x) => x.productId === productId).discountedPrice &&
    prices.find((x) => x.productId === productId).discountedPrice !==
      prices.find((x) => x.productId === productId).price
  ) {
    return (
      <>
        <Row justify={fromProductPage ? "start" : "end"} align="middle">
          <Col span={fromProductPage ? 0 : 12}></Col>
          <Col span={12} className="old-price">
            {
              prices.find((x) => parseInt(x.productId) === parseInt(productId))
                .price
            }
            <br /> <span>{t("ProductPage.HT") + " €"}</span>
          </Col>
        </Row>
        <Row
          justify={fromProductPage ? "start" : "end"}
          align="middle"
          style={{ width: "100%" }}
        >
          <Col span={12} className="unit-price">
            {
              prices.find((x) => parseInt(x.productId) === parseInt(productId))
                .unitPrice
            }{" "}
            <br /> <span>{t("ProductPage.HT") + " €"}</span>
            <sub>/{t("ProductPage.UnitPrice")}</sub>
          </Col>
          <Col span={12} className="card-price">
            {
              prices.find((x) => parseInt(x.productId) === parseInt(productId))
                .discountedPrice
            }{" "}
            <br /> <span>{t("ProductPage.HT") + " €"}</span>
            <sub>/{t("ProductPage.PerBox")}</sub>
          </Col>
        </Row>
      </>
    );
  } else if (
    prices.some((x) => x.productId === productId) &&
    prices.find((x) => parseInt(x.productId) === parseInt(productId)).price !==
      0
  ) {
    return (
      <Row
        justify={fromProductPage ? "start" : "end"}
        align="middle"
        style={{ width: "100%" }}
      >
        <Col span={12} className="unit-price">
          {
            prices.find((x) => parseInt(x.productId) === parseInt(productId))
              .unitPrice
          }{" "}
          <br /> <span>{t("ProductPage.HT") + " €"}</span>
          <sub>/{t("ProductPage.UnitPrice")}</sub>
        </Col>
        <Col span={12} className="card-price">
          {
            prices.find((x) => parseInt(x.productId) === parseInt(productId))
              .price
          }{" "}
          <br /> <span>{t("ProductPage.HT") + " €"}</span>
          <sub>/{t("ProductPage.PerBox")}</sub>
        </Col>
      </Row>
    );
  }
  return "";
};

export const GetDiscountedPriceForProductPage = (
  productId,
  prices,
  t
) => {
  if (
    prices.some((x) => x.productId === productId) &&
    prices.find((x) => x.productId === productId).discountedPrice &&
    prices.find((x) => x.productId === productId).discountedPrice !==
      prices.find((x) => x.productId === productId).price
  ) {
    return (
      <>
        <Row justify="start" align="middle">
          <Col span={0}></Col>
          <Col span={12} className="old-price">
            {
              prices.find((x) => parseInt(x.productId) === parseInt(productId))
                .price
            }
            <span>{t("ProductPage.HT") + " €"}</span>
          </Col>
        </Row>
        <Row justify="start" align="middle" style={{ width: "100%" }}>
          <Col span={12} className="card-price">
            {
              prices.find((x) => parseInt(x.productId) === parseInt(productId))
                .discountedPrice
            }
            <span>{t("ProductPage.HT") + " €"}</span>
            <sub>/{t("ProductPage.PerBox")}</sub>
          </Col>
        </Row>
      </>
    );
  } else if (
    prices.some((x) => x.productId === productId) &&
    prices.find((x) => parseInt(x.productId) === parseInt(productId)).price !==
      0
  ) {
    return (
      <Row justify="start" align="middle" style={{ width: "100%" }}>
        <Col span={12} className="card-price">
          {
            prices.find((x) => parseInt(x.productId) === parseInt(productId))
              .price
          }{" "}
          <span>{t("ProductPage.HT") + " €"}</span>
          <sub>/{t("ProductPage.PerBox")}</sub>
        </Col>
      </Row>
    );
  }
  return "";
};

export const GetDiscountedPriceForCart = (productId, prices) => {
  if (
    prices.some((x) => x.productId === productId) &&
    prices.find((x) => x.productId === productId).discountedPrice &&
    prices.find((x) => x.productId === productId).discountedPrice !==
      prices.find((x) => x.productId === productId).price
  ) {
    return prices.find((x) => parseInt(x.productId) === parseInt(productId))
      .discountedPrice;
  } else if (
    prices.some((x) => x.productId === productId) &&
    prices.find((x) => parseInt(x.productId) === parseInt(productId)).price !==
      0
  ) {
    return prices.find((x) => parseInt(x.productId) === parseInt(productId))
      .price;
  }
  return 0;
};

export const ValidateToken = () => {
  let expTime;
  let isValid;
  if (Cookie.get("jwtToken")) {
    expTime = jwt_decode(Cookie.get("jwtToken")).exp;
  }
  if (
    !Cookie.get("jwtToken") ||
    Cookie.get("jwtToken") === "" ||
    expTime < Date.now() / 1000
  ) {
    isValid = false;
  } else {
    isValid = true;
  }
  return isValid;
};

export const CategoryNameInProduct = (categories, categoryId, languageCode) => {
  let name = "";

  if (categories && categories.some((x) => x.id === categoryId)) {
    let nameObject = categories.find((x) => x.id === categoryId).name;
    if (nameObject && nameObject.some((x) => x.languageCode === languageCode)) {
      name = nameObject.find((x) => x.languageCode === languageCode).text;
    }
  }
  return name;
};

export const CategoryName = (category, languageCode) => {
  let categoryName = "";
  if (category && category.name && category.name.length > 0) {
    categoryName = category.name.some((x) => x.languageCode === languageCode)
      ? category.name.find((x) => x.languageCode === languageCode).text
      : category.name[0].text;
  }
  return categoryName;
};

export const ManufacturerName = (manufacturer, languageCode) => {
  let manufacturerName = "";
  if (manufacturer && manufacturer.name && manufacturer.name.length > 0) {
    manufacturerName = manufacturer.name.some(
      (x) => x.languageCode === languageCode
    )
      ? manufacturer.name.find((x) => x.languageCode === languageCode).text
      : manufacturer.name[0].text;
  }
  return manufacturerName;
};

export const PostOrPageTitle = (p, languageCode) => {
  let postOrPageTitle = "";
  if (p && p.title && p.title.length > 0) {
    postOrPageTitle = p.title.some((x) => x.languageCode === languageCode)
      ? p.title.find((x) => x.languageCode === languageCode).text
      : p.title[0].text;
  }
  return postOrPageTitle;
};

export const PostOrPageExcerpt = (p, languageCode) => {
  let postOrPageExcerpt = "";
  if (p.excerpt && p.excerpt.length > 0) {
    postOrPageExcerpt = p.excerpt.some((x) => x.languageCode === languageCode)
      ? p.excerpt.find((x) => x.languageCode === languageCode).text
      : p.excerpt[0].text;
  }
  return postOrPageExcerpt;
};

export const ProductName = (product, languageCode) => {
  let productName = "";
  if (product && product.name && product.name.length > 0) {
    productName = product.name.some((x) => x.languageCode === languageCode)
      ? product.name.find((x) => x.languageCode === languageCode).text
      : product.name[0].text;
  }
  return productName;
};

export const EditorText = (editor, languageCode) => {
  let editorText = "";
  let compositionText = "";
  let boxText = "";
  let periodText = "";

  if (editor.description && editor.description.length > 0) {
    editorText = editor.description.some((x) => x.languageCode === languageCode)
      ? editor.description.find((x) => x.languageCode === languageCode).text
      : editor.description[0].text;
  }

  if (
    editor.compositionDescription &&
    editor.compositionDescription.length > 0
  ) {
    compositionText = editor.compositionDescription.some(
      (x) => x.languageCode === languageCode
    )
      ? editor.compositionDescription.find(
          (x) => x.languageCode === languageCode
        ).text
      : editor.compositionDescription[0].text;
  }

  if (editor.boxDescription && editor.boxDescription.length > 0) {
    boxText = editor.boxDescription.some((x) => x.languageCode === languageCode)
      ? editor.boxDescription.find((x) => x.languageCode === languageCode).text
      : editor.boxDescription[0].text;
  }

  if (editor.periodDescription && editor.periodDescription.length > 0) {
    periodText = editor.periodDescription.some(
      (x) => x.languageCode === languageCode
    )
      ? editor.periodDescription.find((x) => x.languageCode === languageCode)
          .text
      : editor.periodDescription[0].text;
  }

  return { editorText, compositionText, boxText, periodText };
};

const API_URL = process.env.REACT_APP_API_URL;

export const ImageUrl = (images, productId) => {
  let imageUrl = "";
  if (images.some((x) => x.productId === productId && x.isDefault)) {
    let filePath = images.find(
      (x) => x.productId === productId && x.isDefault
    ).url;
    imageUrl = API_URL + "/" + filePath;
  } else {
    let filePath = images.find((x) => x.productId === productId).url;
    imageUrl = API_URL + "/" + filePath;
  }

  return imageUrl;
};
