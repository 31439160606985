import React, { useState } from "react";
import { Form, Input, Button, Typography, InputNumber, Card, Row } from "antd";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const Calculator = () => {
  const [inputs, setInputs] = useState({
    sum: 0,
    monthQ: 12,
    percent: 24,
  });
  const [paySum, setPaySum] = useState();
  const { t } = useTranslation();

  const handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handlePercentChange = (value) => {
    setInputs({
      ...inputs,
      percent: value,
    });
  };

  const handleMonthChange = (value) => {
    setInputs({
      ...inputs,
      monthQ: value,
    });
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    const { sum, percent, monthQ } = inputs;
    const monthly = percent / 100 / 12;
    if (sum > 0) {
      const payment = Math.floor(
        (sum * monthly) / (1 - Math.pow(1 + monthly, -monthQ))
      );
      setPaySum(payment);
      return;
    }
    setPaySum(0);
  };

  const clear = () => {
    setInputs({
      sum: 0,
      monthQ: 12,
      percent: 11,
    });
    setPaySum(0);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Card bordered={false} className="calculator">
      <Form {...layout} onSubmit={handleSubmit}>
        <Form.Item label={t("Calculator.Sum")}>
          <Input
            size="large"
            name="sum"
            value={inputs.sum}
            placeholder={t("Calculator.EnterAmount")}
            onChange={handleInputChange}
            type="number"
            required
          ></Input>
        </Form.Item>
        <Form.Item label={t("Calculator.Month")}>
          <InputNumber
            size="large"
            value={inputs.monthQ}
            onChange={handleMonthChange}
            min={1}
            max={36}
            required
          ></InputNumber>
        </Form.Item>
        <Form.Item label={t("Calculator.Percent")}>
          <InputNumber
            size="large"
            value={inputs.percent}
            onChange={handlePercentChange}
            min={11}
            max={100}
            required
          ></InputNumber>
        </Form.Item>
        <Row type="flex" justify="space-around" gutter={32} align="middle">
          <Button onClick={clear}>{t("Calculator.Clear")}</Button>
          <Button onClick={handleSubmit} type="default" htmlType="submit">
            {t("Calculator.Calculate")}
          </Button>
        </Row>
      </Form>
      <Row justify="center" align="middle">
        <Title level={2} style={{ color: "#fff", textAlign: "center" }}>
          {paySum !== undefined || paySum === 0
            ? `${t("Calculator.PaySum")} ${paySum}  ֏`
            : ""}
        </Title>
      </Row>
    </Card>
  );
};
export default Calculator;
