import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "./../Api";
import {
  Form,
  Row,
  Card,
  Select,
  Input,
  InputNumber,
  Divider,
  Button,
} from "antd";
import { useTranslation } from "react-i18next";
import { ManufacturerName, CategoryName, ValidateToken } from "./../Common";

const { Option, OptGroup } = Select;
const InputGroup = Input.Group;

const ResultForm = () => {
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState({ minimum: 0 });
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedManufacturer, setSelectedManufacturer] = useState(0);
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      const { data } = await api.categories("isActive").get();
      setCategories(data.sort((a, b) => a.code - b.code));
      setSubCategories(
        data.filter((x) => x.parentId !== 0).sort((a, b) => a.code - b.code)
      );
    };
    const getManufacturers = async () => {
      const { data } = await api.manufacturers().get();
      setManufacturers(data.sort((a, b) => a.code - b.code));
    };
    getCategories();
    getManufacturers();
  }, []);

  const handleSubmit = () => {}; //todo search button for prevent server to die
  const { t, i18n } = useTranslation();

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };
  const handleManufacturerChange = (value) => {
    setSelectedManufacturer(value);
  };
  const handleMaximumPriceChange = (value) => {
    setSelectedPrices((selectedPrices) => ({
      ...selectedPrices,
      maximum: parseFloat(value),
    }));
  };
  const handleMinimumPriceChange = (value) => {
    setSelectedPrices((selectedPrices) => ({
      ...selectedPrices,
      minimum: parseFloat(value),
    }));
  };

  return (
    <Row className="result-form" type="flex" justify="center" align="top">
      <Card
        bordered={false}
        title={t("Result.Filter")}
        style={{ width: "100%" }}
      >
        <Form
          className="result-form"
          layout="inline"
          onSubmit={handleSubmit}
          style={{ textAlign: "center" }}
        >
          <Row type="flex" justify="center" align="middle">
            <Form.Item label={t("Result.Category")} name="categoryId">
              <Select
                name="categoryId"
                onChange={handleCategoryChange}
              >
                <Option value={0}>{t("Global.All")}</Option>
                {categories.map((cat) =>
                  cat.parentId === 0 &&
                  subCategories.some((x) => x.parentId === cat.id) ? (
                    <OptGroup
                      key={cat.id}
                      label={CategoryName(cat, i18n.language)}
                    >
                      <Option value={cat.id} key={cat.id}>
                        {t("Global.All")} {" - "}
                        {CategoryName(cat, i18n.language)}
                      </Option>
                      {subCategories
                        .filter((x) => x.parentId === cat.id)
                        .map((subCat) =>
                          subCategories.filter((x) => x.parentId === subCat.id)
                            .length > 0 ? (
                            <OptGroup
                              id={subCat.id}
                              key={subCat.id}
                              label={CategoryName(subCat, i18n.language)}
                            >
                              <Option value={subCat.id} key={subCat.id}>
                                {CategoryName(subCat, i18n.language)}
                              </Option>
                              {subCategories
                                .filter((x) => x.parentId === subCat.id)
                                .map((child) => (
                                  <Option value={child.id} key={child.id}>
                                    {CategoryName(child, i18n.language)}
                                  </Option>
                                ))}
                            </OptGroup>
                          ) : (
                            <Option value={subCat.id} key={subCat.id}>
                              {CategoryName(subCat, i18n.language)}
                            </Option>
                          )
                        )}
                    </OptGroup>
                  ) : cat.parentId === 0 &&
                    subCategories.filter((x) => x.parentId === cat.id)
                      .length === 0 ? (
                    <Option value={cat.id} key={cat.id}>
                      {CategoryName(cat, i18n.language)}
                    </Option>
                  ) : (
                    ""
                  )
                )}
              </Select>
            </Form.Item>
            <Form.Item label={t("Result.Manufacturer")}>
              <Select
                showSearch
                name="manufacturerId"
                onChange={handleManufacturerChange}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={0}>{t("Global.All")}</Option>
                {manufacturers.map((manufacturer) => (
                  <Option value={manufacturer.id} key={manufacturer.id}>
                    {ManufacturerName(manufacturer, i18n.language)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {ValidateToken() && (
            <Form.Item label={t("Result.Price")}>
              <InputGroup compact>
                <InputNumber
                  min={0}
                  style={{ width: 100, textAlign: "center" }}
                  placeholder={t("Result.Minimum")}
                  onChange={handleMinimumPriceChange}
                />
                <Input
                  style={{
                    width: 30,
                    borderLeft: 0,
                    pointerEvents: "none",
                    backgroundColor: "#fff",
                  }}
                  placeholder="~"
                  disabled
                />
                <InputNumber
                  min={0}
                  style={{ width: 100, textAlign: "center", borderLeft: 0 }}
                  placeholder={t("Result.Maximum")}
                  onChange={handleMaximumPriceChange}
                />
              </InputGroup>
            </Form.Item>
            )}
            <Divider dashed style={{ margin: "12px" }} />
            <Form.Item>
              <Link
                to={{
                  pathname: "/result",
                  search: `?category=${selectedCategory}&manufacturer=${selectedManufacturer}&minimum=${
                    selectedPrices.minimum
                  }&maximum=${
                    selectedPrices.maximum ? selectedPrices.maximum : 0
                  }`
                }}
              >
                <Button type="primary">{t("Result.Find")}</Button>
              </Link>
            </Form.Item>
          </Row>
        </Form>
      </Card>
    </Row>
  );
};
export default ResultForm;
