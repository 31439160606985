import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Product from "./Pages/Product";
import Page from "./Pages/Page";
import Result from "./Components/Result";
import "./i18next";
import FooterBar from "./Components/FooterBar";
import NotFoundPage from "./Pages/NotFoundPage";
import BlogArchive from "./Pages/BlogArchive";
import BlogSingle from "./Pages/BlogSingle";
import SignUp from "./Account/SignUp";
import SignIn from "./Account/SignIn";
import Account from "./Account/Account";
import { ValidateToken } from "./Common";
import Order from "./Components/Order";
import ResetPassword from "./Account/ResetPassword";
import ScrollToTop from "./Components/ScrollToTop";
import Popup from "./Components/Popup";

const IndexPage = () => {
  return (
    <Suspense fallback={<div></div>}>
      <Router>
      <Popup />
      <ScrollToTop />
        <div id="route">
          <Route
            render={() => (
              <Switch>
                <Route exact path="/" component={App} />
                <Route path="/product" component={Product} />
                <Route path="/page" component={Page} />
                <Route path="/result" component={Result} />
                <Route path="/archive" component={BlogArchive} />
                <Route path="/blog" component={BlogSingle} />
                <Route path="/signin" component={SignIn} />
                <Route path="/signup" component={SignUp} />
                <Route path="/order" component={Order} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route
                  path="/account"
                  render={() => {
                    if (ValidateToken()) {
                      return <Account />;
                    }
                    return <Redirect to="/signin" />;
                  }}
                />
                <Route path="/404" component={NotFoundPage} />
                <Route path="*" component={NotFoundPage} />
              </Switch>
            )}
          />
        </div>
        <FooterBar />
      </Router>
    </Suspense>
  );
};

ReactDOM.render(<IndexPage />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
