import React, { useState, useEffect } from "react";
import api from "./../Api";
import AliceCarousel from "react-alice-carousel";

const API_URL = process.env.REACT_APP_API_URL;

const MainCarousel = () => {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    let cleanupFunction = false;

    const getBanners = async () => {
      const imagesUrls = [];
      await api
        .banners()
        .get()
        .then((response) =>
          response.data.forEach((img) => {
            if (img.bannerName.includes("carouselBanner")) {
              imagesUrls.push({
                bannerName: img.bannerName,
                url: `${API_URL}/${img.url}`,
                additionalLink: img.additionalLink,
              });
            }
          })
        );
        if (!cleanupFunction) setBanners(imagesUrls);
    };
    getBanners();
    return () => (cleanupFunction = true);
  }, []);

  return (
      <AliceCarousel
        responsive={{
          0: {
            items: 1,
          },
          1024: { items: 1 },
        }}
        touchTracking
        autoPlay={true}
        infinite={true}
        disableButtonsControls={true}
        disableDotsControls={true}
        autoPlayInterval={4000}
      >
        {banners &&
          banners.length > 0 &&
          banners.map((el, index) => {
            return (
              <div key={index} className="first-slide">
                <a href={el.additionalLink}>
                  <img
                    alt={el.bannerName}
                    src={el.url}
                  />
                </a>
              </div>
            );
          })}
      </AliceCarousel>
  );
};

export default MainCarousel;
