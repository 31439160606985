import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "./../Api";
import { Link, useHistory } from "react-router-dom";
import {
  Form,
  Button,
  Input,
  Checkbox,
  Row,
  Layout,
  Divider,
  Typography,
  Alert,
  Modal,
  Result,
  Spin,
} from "antd";
import { LockOutlined, UserOutlined, MailOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Cookie from "js-cookie";
import Text from "antd/lib/typography/Text";
import Languages from "../Components/Languages.json";

const { Content } = Layout;
const { Title } = Typography;

const SignIn = ({ isModal }) => {
  const [information, setInformation] = useState();
  const [loginError, setLoginError] = useState({});
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [modal, setModal] = useState({ visible: false, email: "" });
  const handleLangSwitch = (lang) => {
    i18n.changeLanguage(lang);
  };
  const [registrationComplited, setRegistrationComplited] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const getInformation = async () => {
      await api
        .informations()
        .get()
        .then((response) => setInformation(response.data[0]));
    };
    getInformation();
  }, []);

  const onFinish = (values) => {
    axios
      .post(process.env.REACT_APP_API_LOGIN_URL, {
        username: values.username,
        password: values.password,
      })
      .then(async (response) => {
        if (response.status === 200 && response.data.token) {
          Cookie.set("jwtToken", response.data.token);
          history.push("/");
          window.location.reload();
        }
      })
      .catch((error) => {
        setLoginError(error.response.data);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showModal = () => {
    setModal({
      visible: true,
    });
  };

  const handleResetMailChange = (e) => {
    setModal((modal) => ({
      ...modal,
      email: e.target.value,
    }));
  };
  const handleOk = (e) => {
    setSpinning(true);
    setModal({
      visible: false,
    });
    axios
      .get(process.env.REACT_APP_API_FORGOT_PASSWORD_URL + modal.email)
      .then(async (response) => {
        setSpinning(false);
        setRegistrationComplited(true);
      })
      .catch((error) => {
        setSpinning(false);
      });
  };

  const handleCancel = (e) => {
    setModal({
      visible: false,
      email: "",
    });
  };

  return (
    <Content
      style={{
        margin: "auto",
        padding: 24,
        background: "#fff",
        borderRadius: 12,
        width: "100%"
      }}
    >
      <Row gutter={16} justify="center">
        <div className="footer-logo">
          <img
            src={
              information &&
              process.env.REACT_APP_API_URL + "/" + information.logoUrl
            }
            alt="logo"
          />
        </div>
        <Divider />
      </Row>
      <Typography>
        <Title style={{ textAlign: "center", paddingBottom: 24 }}>
          {information &&
          information.companyName &&
          information.companyName.some((x) => x.languageCode === i18n.language)
            ? information.companyName.find(
                (x) => x.languageCode === i18n.language
              ).text
            : ""}
        </Title>
        <Text>
          {information &&
          information.description &&
          information.description.some((x) => x.languageCode === i18n.language)
            ? information.description.find(
                (x) => x.languageCode === i18n.language
              ).text
            : ""}
        </Text>
        <Row gutter={16} justify="center">
          <Spin spinning={spinning}>
            {!registrationComplited ? (
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: t("SignIn.UsernameMessage"),
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t("SignIn.Username")}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t("SignIn.PasswordMessage"),
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={t("SignIn.Password")}
                  />
                </Form.Item>
                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>{t("SignIn.RememberMe")}</Checkbox>
                  </Form.Item>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={showModal}
                    className="login-form-forgot"
                  >
                    {t("SignIn.ForgotPassword")}
                  </p>
                  <Modal
                    title={t("SignIn.ResetPassword")}
                    visible={modal.visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    centered
                  >
                    <Form.Item
                      name="email"
                      label={t("SignIn.Email")}
                      rules={[
                        {
                          type: "email",
                          message: t("SignIn.EmailValidationMessage"),
                        },
                        {
                          required: true,
                          message: t("SignIn.EmailMessage"),
                        },
                      ]}
                    >
                      <Input
                        onChange={handleResetMailChange}
                        prefix={
                          <MailOutlined className="site-form-item-icon" />
                        }
                      />
                    </Form.Item>
                  </Modal>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    {t("SignIn.SignIn")}
                  </Button>
                  {t("SignIn.Or")}
                  <Link to="/signup">{" "}{t("SignIn.SignUp")}</Link>
                </Form.Item>
                {Object.values(loginError).length > 0 ? (
                  <Alert message={loginError.message} type="error" />
                ) : (
                  ""
                )}
              </Form>
            ) : (
              <Result
                status="success"
                title={t("SignUp.ForgotComplited")}
                subTitle={t("SignUp.ForgotComplited2")}
              />
            )}
          </Spin>
        </Row>
      </Typography>
      <Row justify="center" align="middle">
        <div className="lang-switch">
          <div>
            {Languages.map((language) => {
              return (
                <Button
                  type="dashed"
                  size="small"
                  onClick={() => handleLangSwitch(language.code)}
                  className={
                    language.code === i18n.language ? "active-language" : ""
                  }
                  key={language.id}
                >
                  <img
                    alt={language.code}
                    width="20"
                    src={apiUrl + `/uploads/${language.code}.svg`}
                  ></img>
                  {language.name}
                </Button>
              );
            })}
          </div>
        </div>
      </Row>
      {isModal ? (
        ""
      ) : (
        <Row
          style={{ margin: "24px 16px 24px 16px" }}
          justify="center"
          align="middle"
        >
          <Link to="/">
            <Button type="danger">{t("Global.BackToHome")}</Button>
          </Link>
        </Row>
      )}
    </Content>
  );
};

export default SignIn;
