import React, { useEffect } from "react";
import api from "./../Api";
import { Button, notification, Typography } from "antd";
import ReactHtmlParser from "react-html-parser";
import { EditorText, PostOrPageTitle } from "../Common";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;

const Popup = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let cleanupFunction = false;
    if (
      (localStorage.getItem("DoNotShowPopup") &&
        !JSON.parse(localStorage.getItem("DoNotShowPopup"))) ||
      !localStorage.getItem("DoNotShowPopup")
    ) {
      const fetchPromotions = async () => {
        await api
          .promotions()
          .get()
          .then((res) => {
            if (!cleanupFunction && res.data.length > 0) {
              const innerClose = () => {
                localStorage.setItem("DoNotShowPopup", JSON.stringify(true));
                notification.close(key);
              };

              const key = `open${Date.now()}`;
              const btn = (
                <Button type="primary" size="small" onClick={innerClose}>
                  {t("Global.Confirm")}
                </Button>
              );

              notification.open({
                message: PostOrPageTitle(res.data[0], i18n.language),
                description: (
                  <Paragraph className="ck-content">
                    {ReactHtmlParser(
                      EditorText(res.data[0], i18n.language).editorText
                    )}
                  </Paragraph>
                ),
                btn,
                key,
                duration: 0,
                onClose: close,
              });
            }
          });
      };
      fetchPromotions();
    }
    return () => (cleanupFunction = true);
  }, [i18n.language, t]);

  const close = () => {
    localStorage.setItem("DoNotShowPopup", JSON.stringify(true));
  };

  return (
    <>
      <div></div>
    </>
  );
};

export default Popup;
