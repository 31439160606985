import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "./../Api";
import {
  Layout,
  Spin,
  Col,
  Row,
  Typography,
  Card,
  List,
  Button,
  Divider,
  Drawer,
  Upload,
  message,
  Modal,
} from "antd";
import HeaderMobile from "../Components/HeaderMobile";
import NavBar from "../Components/NavBar";
import Cookie from "js-cookie";
import { ProductName, ValidateToken } from "../Common";
import { useTranslation } from "react-i18next";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title, Text } = Typography;

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const OrderDetails = ({ order }) => {
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [orderDetails, setOrderDetails] = useState([]);
  useEffect(() => {
    const getOrderDetails = async () => {
      await api
        .orders(order.id)
        .get()
        .then((response) => setOrderDetails(response.data))
        .catch((err) => console.log(err));
    };

    const getProducts = async () => {
      await api
        .products()
        .get()
        .then((response) => {
          setProducts(response.data);
          setLoadingProducts(false);
        });
    };
    getOrderDetails();
    getProducts();
  }, [order.id]);
  const { i18n } = useTranslation();

  return (
    <List
      style={{ width: "100%" }}
      itemLayout="horizontal"
      loading={loadingProducts}
      pagination={{
        onChange: (page) => {
          console.log(page);
        },
      }}
      dataSource={orderDetails}
      footer={"Total " + order.totalSum + " €"}
      renderItem={(row) => (
        <List.Item key={row.id}>
          <List.Item.Meta
            title={ProductName(
              products.find((x) => x.id === row.productId),
              i18n.language
            )}
          />
          <div style={{ marginRight: 12 }}>price: {row.price}</div>
          <div>quantity: {row.quantity}</div>
        </List.Item>
      )}
    />
  );
};

const Account = () => {
  const [orders, setOrders] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingAnimation, setLoadingAnimation] = useState(true);
  const [customer, setCustomer] = useState({});
  const history = useHistory();
  const [orderDrawer, setOrderDrawer] = useState((orderDrawer) => ({
    ...orderDrawer,
    visible: false,
  }));
  const { t } = useTranslation();
  const [images, setImages] = useState({});

  useEffect(() => {
    const getOrders = async () => {
      await api
        .customerDocuments()
        .get()
        .then((response) => setOrders(response.data));
    };

    const getCustomer = async () => {
      await api
        .customer()
        .get()
        .then((response) => {
          setCustomer(response.data);
          setImages({
            fileList: response.data.imageUrl
              ? [
                  {
                    uid: response.data,
                    name: "image",
                    status: "done",
                    url: response.data.imageUrl,
                  },
                ]
              : [],
          });
        });
      setLoading(false);
    };
    const getCartData = async () => {
      await api
        .cart()
        .get()
        .then((res) => {
          setCartCount(res.data.length);
        })
        .catch((err) => {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            console.log(err);
          }
        });
    };
    getOrders();
    getCustomer();
    getCartData();
    setLoadingAnimation(false);
  }, []);

  const handleImageChange = async (img) => {
    setImages((images) => ({ ...images, fileList: img.fileList }));
    let formData = new FormData();
    for (let i = 0; i < images.fileList.length; i++) {
      formData.append("image", images.fileList[i].originFileObj);
    }
    await api
      .uploads()
      .post(formData)
      .then(async (response) => {
        response.data.forEach((res) => {
          api
            .customer()
            .put({
              imageUrl: `${process.env.REACT_APP_API_URL}/${res.path}`,
            })
            .then(() => {
              window.location.reload();
            })
            .catch((err) => console.log(err));
        });
      });
  };

  const uploadButton = (
    <div>
      {images.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  const handleCancel = () => {
    setImages((images) => ({ ...images, previewVisible: false }));
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setImages((images) => ({
      ...images,
      previewImage: file.url || file.preview,
      previewVisible: true,
    }));
  };

  const signOut = () => {
    Cookie.set("jwtToken", "");
    history.push("/signin");
    window.location.reload();
  };

  const showOrderDrawer = (orderUid) => {
    setOrderDrawer({
      orderUid: orderUid,
      visible: true,
    });
  };

  const closeOrderDrawer = (orderUid) => {
    setOrderDrawer({
      orderUid: orderUid,
      visible: false,
    });
  };

  return (
    <Spin spinning={loadingAnimation}>
      <HeaderMobile
        cartCount={cartCount}
        setCartCount={setCartCount}
        setLoadingAnimation={setLoadingAnimation}
      />
      <NavBar
        from={0}
        cartCount={cartCount}
        setCartCount={setCartCount}
        setLoadingAnimation={setLoadingAnimation}
      />
      <Layout>
        <Content
          style={{
            margin: "auto",
            padding: 24,
            background: "#fff",
            minHeight: "100vh",
            borderRadius: 12,
            width: "100%"
          }}
        >
          <Row type="flex" justify="center" gutter={16} align="top">
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
              {customer ? (
                <div>
                  <Card className="account-card">
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      accept=".jpg, .jpeg, .png, .gif"
                      listType="picture-card"
                      fileList={images.fileList}
                      beforeUpload={beforeUpload}
                      onPreview={handlePreview}
                      onChange={handleImageChange}
                    >
                      {!!images.fileList && images.fileList.length >= 1
                        ? null
                        : uploadButton}
                    </Upload>
                    <Modal
                      visible={images.previewVisible}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={images.previewImage}
                      />
                    </Modal>
                    <Title level={2}>{customer.businessName}</Title>
                    <Text>{customer.altName}</Text>
                  </Card>
                </div>
              ) : (
                ""
              )}
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
              <ul className="customer-details">
                <li>{customer.TIN}</li>
                <li>{customer.legalAddress}</li>
                <li>{customer.email}</li>
                <li>{customer.country}</li>
                <li>{customer.city}</li>
                <li>{customer.phone1}</li>
                <li>{customer.phone2}</li>
                <li>{customer.address1}</li>
                <li>{customer.address2}</li>
              </ul>
            </Col>
          </Row>
          <Row type="flex" justify="center" align="middle">
            <List
              style={{ width: "100%" }}
              itemLayout="horizontal"
              loading={loading}
              pagination={{
                onChange: (page) => {
                  console.log(page);
                },
              }}
              dataSource={orders}
              renderItem={(order) => (
                <List.Item key={order.id}>
                  <List.Item.Meta
                    title={
                      new Date(order.date).getFullYear() +
                      "-" +
                      (new Date(order.date).getMonth() + 1) +
                      "-" +
                      new Date(order.date).getDate()
                    }
                    description={order.totalSum + " €"}
                  />
                  <Button onClick={() => showOrderDrawer(order.id)}>
                    Show
                  </Button>
                  <Drawer
                    title={t("Cart.CartTitle")}
                    width={window.innerWidth < 576 ? "100%" : "50%"}
                    closable={true}
                    onClose={() => closeOrderDrawer(order.id)}
                    visible={
                      orderDrawer.visible && orderDrawer.orderUid === order.id
                    }
                  >
                    <OrderDetails order={order} />
                  </Drawer>
                </List.Item>
              )}
            />
          </Row>
          <Divider />
          {ValidateToken() ? (
            <Row type="flex" justify="end" align="middle">
              <Button type="ghost" onClick={signOut}>
                Logout
              </Button>
            </Row>
          ) : (
            ""
          )}
        </Content>
      </Layout>
    </Spin>
  );
};
export default Account;
