import React, { useState, useEffect } from "react";
import api from "./../Api";
import { Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import "react-alice-carousel/lib/alice-carousel.css";
import Products from "../Components/Products";
import { ValidateToken } from "../Common";

const { Title } = Typography;

const FetchProducts = (categoryId) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    let cleanupFunction = false;
    if (categoryId) {
      const getProducts = async () => {
        await api
          .categoryRelatedProducts()
          .get({
            params: {
              categoryId: categoryId,
              manufacturerId: 0,
              searchText: "",
            },
          })
          .then(async (response) => {
            setProducts(response.data);
          });
      };
      const getCategories = async () => {
        const { data } = await api.categories("isActive").get();
        if (!cleanupFunction)
          setCategories(data.filter((x) => x.id === categoryId));
      };
      const getCartData = async () => {
        if (ValidateToken()) {
          await api
            .cart()
            .get()
            .then((res) => {
              if (!cleanupFunction) {
                setCartCount(res.data.length);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      };
      getCartData();
      getCategories();
      getProducts();
      return () => (cleanupFunction = true);
    }
  }, [categoryId]);
  return {
    products,
    categories,
    cartCount,
  };
};

const Relations = ({
  categoryId,
  exceptId,
  cartData,
  setCartData,
  setCartCount,
}) => {
  const { products, categories, cartCount } = FetchProducts(categoryId);

  const { t } = useTranslation();

  const relationsQuantity = () => {
    return products.filter((x) => x.isActive && x.id !== exceptId).length;
  };

  return (
    <div
      style={{
        margin: "24px 16px",
        padding: 24,
        background: "#fff",
        borderRadius: 12,
      }}
    >
      {relationsQuantity() > 0 ? (
        <>
          <Title level={3}>{t("Relations.Title")}</Title>
          <Row
            type="flex"
            justify="start"
            gutter={16}
            align="top"
            id="products"
          >
            <Products
              products={products.filter((x) => x.id !== exceptId)}
              categories={categories}
              cartData={cartData}
              setCartData={setCartData}
              cartCount={cartCount}
              setCartCount={setCartCount}
              setSpinning={false}
              fromPage="Relations"
              productsIds={products.map((x) => x.id)}
            />
          </Row>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
export default Relations;
