import { React } from "react";
import { Card, Spin } from "antd";
import { CategoryName } from "./../Common";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

const CategoriesCards = ({ categories, spinning }) => {
  const { i18n } = useTranslation();

  return (
    <Spin
      indicator={
        <LoadingOutlined style={{ fontSize: 24, color: "#2bbef9" }} spin />
      }
      spinning={spinning}
    >
      <Card
        className="categories-cards"
        style={{ width: "100%", marginTop: 24, marginBottom: 24 }}
      >
        {categories &&
          categories
            .filter((x) => x.parentId === 0)
            .sort((a, b) => a.code - b.code)
            .map((cat) => {
              return (
                <Link
                  key={cat.id}
                  to={{
                    pathname: "/result",
                    search: `?category=${cat.id}`,
                  }}
                >
                  <Card.Grid className="card-grid">
                    <img
                      width="36px"
                      src={cat.imageUrl}
                      alt={CategoryName(cat, i18n.language)}
                    />
                    {CategoryName(cat, i18n.language)}
                  </Card.Grid>
                </Link>
              );
            })}
      </Card>
    </Spin>
  );
};
export default CategoriesCards;
