import React, { useState, useEffect } from "react";
import { Row, Col, Layout, Spin } from "antd";
import MainCarousel from "./Components/Carousel";
import "./App.css";
import Products from "./Components/Products";
import NavBar from "./Components/NavBar";
import api from "./Api";
import SEO from "./Components/SEO";
import HeaderMobile from "./Components/HeaderMobile";
import BlogArchive from "./Pages/BlogArchive";
import CategoriesCards from "./Components/CategoriesCards";
import { LoadingOutlined } from "@ant-design/icons";
import { ValidateToken } from "./Common";

const { Content } = Layout;
const API_URL = process.env.REACT_APP_API_URL;

const App = () => {
  const [products, setProducts] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [loadingAnimation, setLoadingAnimation] = useState(true);
  const [banners, setBanners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [menuLoading, setMenuLoading] = useState(true);
  const [cartData, setCartData] = useState([]);

  useEffect(() => {
    let cleanupFunction = false;

    const getProducts = async () => {
      await api
        .featuredProducts()
        .get()
        .then((response) => {
          if (!cleanupFunction)
            setProducts(response.data.sort((a, b) => a.ordering - b.ordering));
        });
    };
    const getCartData = async () => {
      if (ValidateToken()) {
        await api
          .cart()
          .get()
          .then((res) => {
            if (!cleanupFunction) {
              setCartData(res.data);
              setCartCount(res.data.length);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    const getBanners = async () => {
      const imagesUrls = [];
      await api
        .banners()
        .get()
        .then((response) => {
          response.data.forEach((img) => {
            if (
              img.bannerName === "rightBanner" ||
              img.bannerName === "rightBanner2" ||
              img.bannerName === "bottomBanner" ||
              img.bannerName === "leftBanner"
            ) {
              imagesUrls.push({
                bannerName: img.bannerName,
                url: `${API_URL}/${img.url}`,
                additionalLink: img.additionalLink,
              });
            }
          });
        });
      if (!cleanupFunction) setBanners(imagesUrls);
    };
    const getCategories = async () => {
      const { data } = await api.categories("isActive").get();
      if (!cleanupFunction) setCategories(data);
      setMenuLoading(false);
      setLoadingAnimation(false);
    };
    getProducts();
    getCartData();
    getBanners();
    getCategories();
    return () => (cleanupFunction = true);
  }, []);

  return (
    <Spin
      indicator={
        <LoadingOutlined style={{ fontSize: 48, color: "#2bbef9" }} spin />
      }
      spinning={loadingAnimation}
    >
      <HeaderMobile cartCount={cartCount} setCartCount={setCartCount} />
      <NavBar from={0} cartCount={cartCount} setCartCount={setCartCount} />
      <Layout
        style={{
          opacity: loadingAnimation ? 0 : 1,
          transition: "opacity 0.8s ease-in",
        }}
      >
        <div className="desktop-tablet-carousel">
          <MainCarousel />
        </div>
        <Content>
          <SEO
            pageProps={{
              title: "Edelweiss Group Market",
              thumbnail: "",
              url: window.location,
            }}
          />
          <Row
            type="flex"
            justify="space-between"
            align="top"
            style={{ margin: "0 12px" }}
          >
            <CategoriesCards categories={categories} spinning={menuLoading} />
            <Row style={{ width: "100%" }} gutter={{ xs: 0, lg: 16 }}>
              <Col xs={{ span: 24 }} lg={{ span: 18 }}>
                <Products
                  products={products}
                  categories={categories}
                  cartData={cartData}
                  setCartData={setCartData}
                  cartCount={cartCount}
                  setCartCount={setCartCount}
                  setSpinning={setLoadingAnimation}
                  fromPage="App"
                  productsIds={products.map((x) => x.id)}
                />
              </Col>
              <Col xs={{ span: 0 }} lg={{ span: 6 }}>
                {banners &&
                banners.length > 0 &&
                banners.some((x) => x.bannerName === "rightBanner") ? (
                  <a
                    href={
                      banners.find((x) => x.bannerName === "rightBanner")
                        .additionalLink
                    }
                  >
                    <img
                      src={
                        banners.find((x) => x.bannerName === "rightBanner").url
                      }
                      alt="rightBanner"
                      className="rightTowerBanner"
                    />
                  </a>
                ) : (
                  ""
                )}
                {banners &&
                banners.length > 0 &&
                banners.some((x) => x.bannerName === "rightBanner2") ? (
                  <a
                    href={
                      banners.find((x) => x.bannerName === "rightBanner2")
                        .additionalLink
                    }
                  >
                    <img
                      src={
                        banners.find((x) => x.bannerName === "rightBanner2").url
                      }
                      alt="rightBanner2"
                      className="rightTowerBanner"
                    />
                  </a>
                ) : (
                  ""
                )}
                {banners &&
                banners.length > 0 &&
                banners.some((x) => x.bannerName === "leftBanner") ? (
                  <a
                    href={
                      banners.find((x) => x.bannerName === "leftBanner")
                        .additionalLink
                    }
                  >
                    <img
                      src={
                        banners.find((x) => x.bannerName === "leftBanner").url
                      }
                      alt="leftBanner"
                      className="leftTowerBanner"
                    />
                  </a>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                {banners &&
                banners.length > 0 &&
                banners.some((x) => x.bannerName === "bottomBanner") ? (
                  <a
                    href={
                      banners.find((x) => x.bannerName === "bottomBanner")
                        .additionalLink
                    }
                  >
                    <img
                      src={
                        banners.find((x) => x.bannerName === "bottomBanner").url
                      }
                      alt="bottomBanner"
                      className="bottomBanner"
                    />
                  </a>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row>
              <BlogArchive from={"App"} />
            </Row>
          </Row>
        </Content>
      </Layout>
    </Spin>
  );
};

export default App;
