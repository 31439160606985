import React from "react";
import { useHistory } from "react-router-dom";
import { ShoppingCartOutlined } from "@ant-design/icons";
import {  Button } from "antd";

const Cart = ({ from, cartCount, setCartCount }) => {


  let history = useHistory();

  const showOrderDrawer = () => {
    history.push('/order')
  };

  return (
    <div>
      <Button
        type="default"
        className="cart"
        shape="round"
        icon={<ShoppingCartOutlined />}
        onClick={showOrderDrawer}
      >
        <div className="cart-count">
          {cartCount}
        </div>
      </Button>
    </div>
  );
};
export default Cart;
