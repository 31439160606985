import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "./../Api";
import {
  Row,
  Card,
  Typography,
  Skeleton,
  Col,
  Tag,
  Button,
  List,
  Avatar,
  Divider,
  InputNumber,
  message,
} from "antd";
import {
  AppstoreOutlined,
  MenuOutlined,
  ShoppingCartOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  CategoryNameInProduct,
  ManufacturerName,
  ProductName,
  GetDiscountedPrice,
  GetDiscount,
  GetDiscountedPriceForCart,
  ValidateToken,
  ImageUrl,
} from "./../Common";
import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";

const { Title } = Typography;
const { Meta } = Card;

const FetchProducts = (productsIds) => {
  const [images, setImages] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [prices, setPrices] = useState([]);
  const [info, setInfo] = useState({});
  const [skeletonLoading, setSkeletonLoading] = useState(true);

  useEffect(() => {
    let cleanupFunction = false;

    const getManufacturers = async () => {
      const { data } = await api.manufacturers().get();
      if (!cleanupFunction) setManufacturers(data);
    };
    const getInfo = async () => {
      const { data } = await api.informations().get();
      if (!cleanupFunction) setInfo(data);
    };
    const getImages = async () => {
      if (productsIds.length > 0) {
        await api
          .productsImages()
          .post({ productsIds: productsIds })
          .then((response) => {
            if (!cleanupFunction) setImages(response.data);
          });
      }
    };
    const getPrices = async () => {
      if (ValidateToken()) {
        await api
          .prices()
          .get()
          .then((res) => {
            if (!cleanupFunction) {
              setPrices(res.data);
              if (res.data && res.data.length > 0) {
                setSkeletonLoading(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setSkeletonLoading(false);
      }
    };
    getManufacturers();
    getImages();
    getPrices();
    getInfo();
    return () => (cleanupFunction = true);
  }, [productsIds]);
  return {
    images,
    manufacturers,
    prices,
    info,
    skeletonLoading,
  };
};
const Products = ({
  products,
  categories,
  cartData,
  setCartData,
  cartCount,
  setCartCount,
  setSpinning,
  fromPage,
  productsIds,
}) => {
  const { images, manufacturers, prices, info, skeletonLoading } =
    FetchProducts(productsIds);
  const { t, i18n } = useTranslation();
  const [selectedRowType, setSelectedRowType] = useState(
    sessionStorage.getItem("rowType")
      ? sessionStorage.getItem("rowType")
      : "grid"
  );
  const [orderedProducts, setOrderedProducts] = useState([]);
  const [addToCartLoading, setAddToCartLoading] = useState({
    productId: -1,
    state: false,
  });

  useEffect(() => {
    const getCartData = async () => {
      if (ValidateToken()) {
        await api
          .cart()
          .get()
          .then((res) => {
            setCartData(res.data);
            setCartCount(res.data.length);
            let orderedPrds = [];
            res.data.forEach((element) => {
              orderedPrds.push({
                productId: element.productId,
                quantity: Number(element.quantity),
              });
            });
            setOrderedProducts(orderedPrds);
          });
      }
    };
    getCartData();
  }, [setCartData, setCartCount]);

  const onQuantityChange = async (value, productId) => {
    let tmpRows = [...orderedProducts];
    if (tmpRows.some((x) => x.productId === productId)) {
      tmpRows.find((x) => x.productId === productId).quantity = value;
    } else {
      tmpRows.push({ productId: productId, quantity: value });
    }
    setOrderedProducts(tmpRows);
  };

  const onCartQuantityChange = (product, prices) => {
    setAddToCartLoading({ productId: product.id, state: true });
    let uid = "";
    if (Cookie.get("jwtToken")) {
      let decoded = jwt_decode(Cookie.get("jwtToken"));
      uid = decoded.id;
    }
    if (uid !== "") {
      let data = {
        productId: Number(product.id),
        quantity:
          orderedProducts.find((x) => x.productId === product.id)?.quantity ??
          0,
        customerUid: uid,
        price: product.byOrder
          ? 0
          : GetDiscountedPriceForCart(product.id, prices),
      };
      api
        .addToCart()
        .post(data)
        .then((res) => {
          setCartData(res.data);
          setCartCount(res.data.length);
          let orderedPrds = [];
          res.data.forEach((element) => {
            orderedPrds.push({
              productId: element.productId,
              quantity: Number(element.quantity),
            });
          });
          setOrderedProducts(orderedPrds);
          message.success(`${t("ProductPage.CartUpdated")}`, 2);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(
          setTimeout(
            () => setAddToCartLoading({ productId: product.id, state: false }),
            800
          )
        );
    }
  };

  const onCartQuantityDelete = async (productId) => {
    if (cartData) {
      await api
        .deleteRowCart(
          cartData.find((x) => x.productId === productId)?.id ?? null
        )
        .delete()
        .then((res) => {
          setCartData(res.data);
          setCartCount(res.data.length);
          let orderedPrds = [];
          res.data.forEach((element) => {
            orderedPrds.push({
              productId: element.productId,
              quantity: Number(element.quantity),
            });
          });
          setOrderedProducts(orderedPrds);
          message.success(`${t("ProductPage.CartUpdated")}`, 2);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleRowTypeClick = (type) => {
    sessionStorage.setItem("rowType", type);
    setSelectedRowType(type);
  };

  return (
    <div className="products-grids" style={{ width: "100%" }}>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className="selected-products-header"
      >
        <Title level={3}>
          {fromPage === "App" ? t("SelectedProducts.1") : ""}
        </Title>
        <div>
          <AppstoreOutlined
            className="row-type"
            style={{ marginRight: 6 }}
            onClick={() => handleRowTypeClick("grid")}
          />
          <MenuOutlined
            className="row-type"
            onClick={() => handleRowTypeClick("row")}
          />
        </div>
      </Row>
      <Divider dashed />
      {selectedRowType === "grid" ? (
        <Row id="products" type="flex" justify="start" gutter={16} align="top">
          {products
            .filter((x) => images && images.some((y) => y.productId === x.id))
            .map((product) => (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: fromPage === "Relations" ? 6 : 8 }}
                style={{ marginBottom: 12 }}
                key={product.id}
              >
                <Tag
                  className="category-in-product"
                  hidden={!product.categoryId}
                >
                  {CategoryNameInProduct(
                    categories,
                    product.categoryId,
                    i18n.language
                  )}
                </Tag>
                {GetDiscount(product.id, prices)}
                {info && info.showManufacturerLogoInProductCard ? (
                  <div className="manufacturer-logo-in-product">
                    {manufacturers &&
                    manufacturers.some(
                      (x) => x.id === product.manufacturerId
                    ) &&
                    manufacturers.filter((x) => x.id === product.manufacturerId)
                      .imageUrl !== "" &&
                    images !== undefined &&
                    images.some((x) => x.productId === product.id) ? (
                      <img
                        width="100%"
                        src={
                          manufacturers.find(
                            (x) => x.id === product.manufacturerId
                          ).imageUrl
                        }
                        alt={ManufacturerName(
                          manufacturers.find(
                            (x) => x.id === product.manufacturerId
                          ).name,
                          i18n.language
                        )}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <Card
                  cover={
                    <Skeleton
                      loading={skeletonLoading}
                      active
                      avatar={{ shape: "square" }}
                      title={{ width: 0 }}
                      paragraph={{ rows: 0, width: 0 }}
                    >
                      <Link
                        style={{ color: "#000000" }}
                        to={{
                          pathname: `/product/${product.slug}`,
                          productProps: {
                            id: product.id,
                          },
                        }}
                      >
                        <img
                          className="cardImgSkeleton"
                          key={product.id}
                          alt={product.name ? product.name[0].text : 0}
                          src={ImageUrl(images, product.id)}
                        />
                      </Link>
                    </Skeleton>
                  }
                  bordered={false}
                  actions={[
                    <div>
                      {ValidateToken() ? (
                        <Row
                          className="add-to-card-icon"
                          justify="start"
                          align="middle"
                        >
                          <Col>
                            <InputNumber
                              style={{ fontSize: 12 }}
                              min={0}
                              value={
                                orderedProducts.some(
                                  (x) => x.productId === product.id
                                )
                                  ? Number(
                                      orderedProducts.find(
                                        (x) => x.productId === product.id
                                      ).quantity
                                    )
                                  : 0
                              }
                              onChange={async (value) =>
                                await onQuantityChange(value, product.id)
                              }
                            />
                          </Col>
                          <Col>
                            <Button
                              type="dashed"
                              icon={<ShoppingCartOutlined />}
                              size="middle"
                              disabled={!product.inStock}
                              loading={
                                addToCartLoading.productId === product.id &&
                                addToCartLoading.state
                              }
                              onClick={() =>
                                onCartQuantityChange(product, prices)
                              }
                            ></Button>
                            <Button
                              style={{ color: "red" }}
                              type="dashed"
                              icon={<DeleteOutlined />}
                              size="middle"
                              disabled={!product.inStock}
                              onClick={async () =>
                                await onCartQuantityDelete(product.id)
                              }
                            ></Button>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </div>,
                    <div className="percent-price">
                      {!product.byOrder || !prices.length !== 0
                        ? GetDiscountedPrice(product.id, prices, t)
                        : t("SelectedProducts.ByOrder")}
                    </div>,
                  ]}
                >
                  <Skeleton
                    loading={skeletonLoading}
                    paragraph={{ rows: 0 }}
                    title={{ width: "100%" }}
                    active
                  >
                    <Link
                      style={{ color: "#000000" }}
                      to={{
                        pathname: `/product/${product.slug}`,
                        productProps: {
                          id: product.id,
                        },
                      }}
                    >
                      <Meta style={{ bottom: ValidateToken() ? "65px" : "20px"}} title={ProductName(product, i18n.language)} />
                    </Link>
                  </Skeleton>
                </Card>
              </Col>
            ))}
        </Row>
      ) : (
        <Row id="products-row" type="flex" justify="start" align="top">
          <List
            itemLayout="horizontal"
            style={{ width: "100%" }}
            dataSource={products.filter(
              (x) => images && images.some((y) => y.productId === x.id)
            )}
            renderItem={(item) => (
              <List.Item className="products-list" key={item.id}>
                <List.Item.Meta
                  avatar={<Avatar src={ImageUrl(images, item.id)} />}
                  title={
                    <Link
                      className="product-list-row"
                      to={{
                        pathname: `/product/${item.slug}`,
                        productProps: {
                          id: item.id,
                        },
                      }}
                    >
                      {ProductName(item, i18n.language)}
                    </Link>
                  }
                  description={[
                    <Row key={1} justify="end" align="middle">
                      <Col span={4}>{GetDiscount(item.id, prices)}</Col>
                      <Col span={18} className="percent-price">
                        {!item.byOrder || !prices.length !== 0
                          ? GetDiscountedPrice(item.id, prices, t)
                          : t("SelectedProducts.ByOrder")}
                      </Col>
                    </Row>,
                  ]}
                />
                {ValidateToken() ? (
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ width: "25%" }}
                  >
                    <Col span={12}>
                      <Button
                        type="dashed"
                        icon={<ShoppingCartOutlined />}
                        size="middle"
                        disabled={!item.inStock}
                        loading={
                          addToCartLoading.productId === item.id &&
                          addToCartLoading.state
                        }
                        onClick={() => onCartQuantityChange(item, prices)}
                      ></Button>
                      <Button
                        style={{ color: "red" }}
                        type="dashed"
                        icon={<DeleteOutlined />}
                        size="middle"
                        disabled={!item.inStock}
                        onClick={async () =>
                          await onCartQuantityDelete(item.id)
                        }
                      ></Button>
                    </Col>
                    <Col span={12}>
                      <InputNumber
                        style={{ fontSize: 12 }}
                        min={0}
                        value={
                          orderedProducts.some((x) => x.productId === item.id)
                            ? Number(
                                orderedProducts.find(
                                  (x) => x.productId === item.id
                                ).quantity
                              )
                            : 0
                        }
                        onChange={(value) => onQuantityChange(value, item.id)}
                      />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </List.Item>
            )}
          />
        </Row>
      )}
    </div>
  );
};
export default Products;
