import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import api from "./../Api";
import { Row, Col, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import logo from "../Images/footer-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import packageJson from "./../../package.json";
import master from "../Images/master.svg";
import visa from "../Images/visa.svg";
import maestro from "../Images/maestro.svg";
import paypal from "../Images/paypal.svg";
import { PostOrPageTitle } from "../Common";
import { LoadingOutlined } from "@ant-design/icons";

const FooterBar = () => {
  const [pages, setPages] = useState([]);
  const [info, setInfo] = useState({});
  const [modal, setModal] = useState({ visible: false });
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [spinning, setSpinning] = useState(true);

  useEffect(() => {
    const getPages = async () => {
      setSpinning(true);
      await api
        .pages()
        .get()
        .then((response) =>
          setPages(response.data.filter((x) => x.isInNavigation))
        );
    };
    const getInformation = async () => {
      await api
        .informations()
        .get()
        .then((response) => {
          setInfo(response.data[0]);
          setSpinning(false);
        });
    };
    getPages();
    getInformation();
  }, [location]);

  const showModal = () => {
    setModal({
      visible: true,
    });
  };

  const handleOk = (e) => {
    setModal({
      visible: false,
    });
  };

  const handleCancel = (e) => {
    setModal({
      visible: false,
    });
  };

  return (
    <Spin
      indicator={
        <LoadingOutlined style={{ fontSize: 24, color: "#2bbef9" }} spin />
      }
      spinning={spinning}
    >
      <div className="footer">
        <Row type="flex" justify="space-between" className="center-r">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            lg={{ span: 6 }}
            className="pages-col"
          >
            <div>
              <div className="footer-logo">
                <img src={logo} alt="logo" />
              </div>
              <div className="after-logo">
                {info && (
                  <div>
                    <div
                      onClick={showModal}
                      style={{ cursor: "pointer" }}
                      className="footer-menu"
                    >
                      <FontAwesomeIcon icon={faMapMarker} />
                      {t("Footer.FindUs")}
                    </div>
                    <Modal
                      title={t("Footer.ContactUs")}
                      visible={modal.visible}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      width="80%"
                      style={{ margin: "auto" }}
                    >
                      <iframe
                        title="Google maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d288886.95025474275!2d7.68229114831814!3d48.473216415068734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796b58dc3bf5d2d%3A0x57aa25987e367310!2s20%20Rue%20de%20Mulhouse%2C%2067118%20Geispolsheim%2C%20France!5e0!3m2!1sen!2s!4v1604664349015!5m2!1sen!2s"
                        frameborder="0"
                        style={{ border: 0, height: 450 }}
                        allowfullscreen=""
                      ></iframe>
                    </Modal>
                    <p>
                      {info.address1 &&
                      info.address1.some(
                        (x) => x.languageCode === i18n.language
                      )
                        ? info.address1.filter(
                            (x) => x.languageCode === i18n.language
                          ).text
                        : ""}
                    </p>
                    <p>
                      {info.address2 &&
                      info.address2.some(
                        (x) => x.languageCode === i18n.language
                      )
                        ? info.address2.filter(
                            (x) => x.languageCode === i18n.language
                          ).text
                        : ""}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            lg={{ span: 6 }}
            className="find-us-col"
          >
            <div className="footer-menu">
              {pages &&
                pages.map((page) => (
                  <Link key={page.id} to={`/page/${page.slug}`}>
                    <li>{PostOrPageTitle(page, i18n.language)}</li>
                  </Link>
                ))}
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
            <div className="social-icons">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.facebook.com"
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.instagram.com"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.youtube.com"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </div>
            <div className="find-us">
              {info && (
                <div className="footer-phones">
                  <a
                    href={`tel:${
                      info.phone1 &&
                      info.phone1.some((x) => x.languageCode === i18n.language)
                        ? info.phone1.find(
                            (x) => x.languageCode === i18n.language
                          ).text
                        : ""
                    }`}
                  >
                    {info.phone1 &&
                    info.phone1.some((x) => x.languageCode === i18n.language)
                      ? info.phone1.find(
                          (x) => x.languageCode === i18n.language
                        ).text
                      : ""}
                  </a>
                  <a
                    href={`tel:${
                      info.phone2 &&
                      info.phone2.some((x) => x.languageCode === i18n.language)
                        ? info.phone2.find(
                            (x) => x.languageCode === i18n.language
                          ).text
                        : ""
                    }`}
                  >
                    {info.phone2 &&
                    info.phone2.some((x) => x.languageCode === i18n.language)
                      ? info.phone2.find(
                          (x) => x.languageCode === i18n.language
                        ).text
                      : ""}
                  </a>
                </div>
              )}
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
            <Row justify="center" align="middle" className="payment-icons">
              <Col span={6}>
                <img src={master} alt="master" />
                <img src={maestro} alt="maestro" />
              </Col>
              <Col>
                <img src={visa} alt="visa" />
                <img src={paypal} alt="paypal" />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle">
          <p className="bottom-bar">
            {packageJson.name} v{packageJson.version} <br />
            Created by
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://owltechagency.com/"
            >
              &nbsp;Owltech Agency © 2019 - {new Date().getFullYear()}
            </a>
          </p>
        </Row>
      </div>
    </Spin>
  );
};
export default FooterBar;
