import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import api from "./../Api";
import { DownOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import {
  Layout,
  Menu,
  Row,
  Col,
  Button,
  Input,
  Modal,
  Avatar,
  Grid,
} from "antd";
import Cart from "./Cart";
import { useTranslation } from "react-i18next";
import SignIn from "../Account/SignIn";
import { ValidateToken } from "../Common";
import Languages from "./Languages.json";
import { PostOrPageTitle } from "./../Common";
import logo from "../Images/logo.svg";
import SiderMenu from "./Sider";

const { Header } = Layout;
const { Search } = Input;
const { useBreakpoint } = Grid;

const usePages = () => {
  const [pages, setPages] = useState([]);
  const [topNavigation, setTopNavigation] = useState([]);
  const [customer, setCustomer] = useState();
  const [info, setInfo] = useState({});

  useEffect(() => {
    const getPages = async () => {
      const { data } = await api.pages().get();
      setPages(
        data
          .filter((x) => x.isInNavigation === true)
          .sort((a, b) => a.ordering - b.ordering)
      );
    };
    const getTopNavigation = async () => {
      const { data } = await api.pages().get();
      setTopNavigation(
        data
          .filter((x) => x.isInTopNavigation === true)
          .sort((a, b) => a.ordering - b.ordering)
      );
    };
    const getCompanyData = async () => {
      if (ValidateToken()) {
        await api
          .customer()
          .get()
          .then((res) => {
            setCustomer(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    const getInfo = async () => {
      const { data } = await api.informations().get();
      setInfo(data[0]);
    };
    getCompanyData();
    getPages();
    getTopNavigation();
    getInfo();
  }, []);

  return {
    pages,
    topNavigation,
    customer,
    info,
  };
};

const NavBar = ({ from, cartCount, setCartCount }) => {
  const { pages, topNavigation, customer, info } = usePages();
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchedText = params.get("search");
  const categoryId = params.get("categoryId");
  const [modalVisibility, setModalVisibility] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [menuLoading, setMenuLoading] = useState(true);
  const [showCategoryMenu, setCategoryMenu] = useState(false);
  const { md, sm } = useBreakpoint();

  useEffect(() => {
    setSearchValue(searchedText);

    const getCategories = async () => {
      const { data } = await api.categories("isActive").get();
      setCategories(
        data.filter((x) => x.parentId === 0).sort((a, b) => a.code - b.code)
      );
      setSubCategories(
        data.filter((x) => x.parentId !== 0).sort((a, b) => a.code - b.code)
      );
      setMenuLoading(false);
    };
    getCategories();
  }, [searchedText]);

  const handleLangSwitch = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleOnSearch = (value) => {
    if (categoryId === null) {
      history.push({
        pathname: "/result",
        search: `?search=${value}`,
      });
    } else {
      history.push({
        pathname: "/result",
        search: `&search=${value}`,
      });
    }
    setSearchValue(value);
    window.location.reload();
  };

  const showModal = () => {
    setModalVisibility(true);
  };

  const pushToAccount = () => {
    history.push({
      pathname: "/account",
    });
  };

  const handleModalOk = (e) => {
    setModalVisibility(false);
  };

  const handleModalCancel = (e) => {
    setModalVisibility(false);
  };

  const handleCategoryButtonClick = () => {
    setCategoryMenu(!showCategoryMenu);
  };

  return (
    <Header className="header" id="header">
      <Row type="flex" align="middle" className="headerBreak">
        <div style={{ maxWidth: "1170px", margin: "auto" }}>
          {topNavigation.map((item) => (
            <Button
              key={item.id}
              type="dashed"
              size="small"
              className="topMenu"
              style={{ marginRight: 24 }}
            >
              <Link to={`/page/${item.slug}`}>
                {item.title &&
                item.title.some((x) => x.languageCode === i18n.language)
                  ? item.title.find((x) => x.languageCode === i18n.language)
                      .text
                  : ""}
              </Link>
            </Button>
          ))}
          <div className="lang-switch">
            <Row type="flex" justify="start" align="middle">
              <PhoneOutlined
                style={{
                  marginRight: 6,
                  fontSize: 18,
                  transform: "scaleX(-1)",
                  verticalAlign: "middle",
                }}
              />
              {info ? (
                <>
                  <span itemProp="telephone">
                    <a
                      style={{fontSize: 28}}
                      href={
                        info.phone1 &&
                        info.phone1.some(
                          (x) => x.languageCode === i18n.language
                        )
                          ? "tel:+" +
                            info.phone1.find(
                              (x) => x.languageCode === i18n.language
                            ).text
                          : ""
                      }
                    >
                      {info.phone1 &&
                      info.phone1.some((x) => x.languageCode === i18n.language)
                        ? info.phone1.find(
                            (x) => x.languageCode === i18n.language
                          ).text
                        : ""}
                    </a>
                  </span>
                  {info.phone2 &&
                  info.phone2.some((x) => x.languageCode === i18n.language) &&
                  info.phone2.find((x) => x.languageCode === i18n.language)
                    .text !== ""
                    ? ", "
                    : ""}
                  <span itemProp="telephone">
                    <a
                      style={{fontSize: 28}}
                      href={
                        info.phone2 &&
                        info.phone2.some(
                          (x) => x.languageCode === i18n.language
                        )
                          ? "tel:+" +
                            info.phone2.find(
                              (x) => x.languageCode === i18n.language
                            ).text
                          : ""
                      }
                    >
                      {info.phone2 &&
                      info.phone2.some((x) => x.languageCode === i18n.language)
                        ? info.phone2.find(
                            (x) => x.languageCode === i18n.language
                          ).text
                        : ""}
                    </a>
                  </span>
                </>
              ) : (
                ""
              )}
            </Row>
            <div>
              {Languages.map((language) => {
                return (
                  <Button
                    type="dashed"
                    size="small"
                    onClick={() => handleLangSwitch(language.code)}
                    className={
                      language.code === i18n.language ? "active-language" : ""
                    }
                    key={language.id}
                  >
                    <img
                      alt={language.code}
                      width="20"
                      src={apiUrl + `/uploads/${language.code}.svg`}
                    ></img>
                    {language.name}
                  </Button>
                );
              })}
            </div>
          </div>
        </div>
      </Row>
      <div style={{ maxWidth: "1170px", margin: "auto" }}>
        <Row
          type="flex"
          align="bottom"
          style={{
            paddingTop: 32,
            marginBottom: 6,
            marginLeft: md || sm ? 12 : 6,
            marginRight: md || sm ? 12 : 6,
          }}
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 6 }}
          >
            <Row>
              <Link to="/">
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
              </Link>
            </Row>
            <Row>
              <Button
                type="primary"
                shape="round"
                icon={<DownOutlined />}
                size="large"
                disabled={from === "ResultPage"}
                loading={menuLoading}
                onClick={handleCategoryButtonClick}
                style={{ textTransform: "uppercase", marginBottom: 6 }}
              >
                {t("Sider.Headline")}
              </Button>
              <SiderMenu
                categories={categories}
                subCategories={subCategories}
                showCategoryMenu={showCategoryMenu}
              />
            </Row>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 18 }}
          >
            <Row type="flex" justify="space-between" align="center">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 16 }}
                md={{ span: 16 }}
                lg={{ span: 18 }}
              >
                <Search
                  placeholder={
                    searchValue ? searchValue : t("NavBar.SearchText")
                  }
                  onSearch={handleOnSearch}
                  size="default"
                  className="ota-search"
                />
              </Col>
              <Col
                xs={{ span: 24 }}
                lg={{ span: 6 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                style={{
                  textAlign: "right",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                {ValidateToken() ? (
                  <div>
                    {customer ? (
                      <Avatar
                        style={{ cursor: "pointer" }}
                        onClick={pushToAccount}
                        src={customer.imageUrl}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <UserOutlined
                    className="user-login-icon"
                    onClick={showModal}
                  />
                )}
                {ValidateToken() && (
                  <Cart
                    from={from}
                    cartCount={cartCount}
                    setCartCount={setCartCount}
                  />
                )}
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="bottom">
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Menu
                  mode="horizontal"
                  defaultSelectedKeys={
                    from !== "ProductPage" ? [`${from}`] : [""]
                  }
                  selectedKeys={[`${from}`]}
                  style={{
                    lineHeight: "40px",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "6px",
                    marginBottom: "6px",
                  }}
                >
                  <Menu.Item key={0}>
                    <Link to="/">{t("Home.1")}</Link>
                  </Menu.Item>
                  {pages.map((page) => (
                    <Menu.Item key={page.id}>
                      <Link to={`/page/${page.slug}`}>
                        {PostOrPageTitle(page, i18n.language)}
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu>
                <Modal
                  title={t("SignIn.SignIn")}
                  visible={modalVisibility}
                  onOk={handleModalOk}
                  onCancel={handleModalCancel}
                  className="sign-in-modal"
                >
                  <SignIn isModal={true} />
                </Modal>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Header>
  );
};

export default NavBar;
