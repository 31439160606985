import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Typography, Row, Layout, Button, Col } from "antd";
import NavBar from "../Components/NavBar";
import { useTranslation } from "react-i18next";
import SEO from "../Components/SEO";
import { ValidateToken } from "../Common";
import api from "./../Api";
import HeaderMobile from "./../Components/HeaderMobile";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const NotFoundPage = () => {
  const [cartCount, setCartCount] = useState(0);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const getCartData = async () => {
      if (ValidateToken()) {
        await api
          .cart()
          .get()
          .then((res) => {
            setCartCount(res.data.length);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    window.scrollTo(0, 0);
    getCartData();
  }, [history]);

  return (
    <div id="not-found-page">
      <HeaderMobile cartCount={cartCount} setCartCount={setCartCount} />
      <NavBar from={-1} cartCount={cartCount} setCartCount={setCartCount} />
      <SEO
        pageProps={{
          title: "Edelweiss Group Market",
          thumbnail: "",
          url: window.location,
        }}
      />
      <Content style={{ margin: "24px 24px", maxWidth: "100%", minHeight: "100vh" }}>
      <Row justify="space-around" align="middle">
        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <Typography className="notFound">
            <Title>404</Title>
            <Paragraph>{t("Global.PageNotFound")}</Paragraph>
            <Link to="/">
            <Button type="primary" key="console">
              {t("Global.BackToHome")}
            </Button>
          </Link>
          </Typography>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default NotFoundPage;
