import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Checkbox,
  Button,
  Typography,
  Row,
  Layout,
  Divider,
  Alert,
  Result,
} from "antd";
import api from "./../Api";
import SEO from "../Components/SEO";
import { useTranslation } from "react-i18next";
import { MailOutlined, LockOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Content } = Layout;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const SignUp = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [information, setInformation] = useState();
  const [loginError, setLoginError] = useState({});
  const [registrationComplited, setRegistrationComplited] = useState(false);

  useEffect(() => {
    const getInformation = async () => {
      await api
        .informations()
        .get()
        .then((response) => setInformation(response.data[0]));
    };
    getInformation();
  }, []);

  const onFinish = (values) => {
    axios
      .post(process.env.REACT_APP_API_REGISTER_URL, {
        username: values.username ? values.username : "",
        password: values.password ? values.password : "",
        email: values.email ? values.email : "",
        businessName: values.businessName,
        altName: values.altName ? values.altName : "",
        TIN: values.TIN ? values.TIN : "",
        country: values.country ? values.country : "",
        city: values.city ? values.city : "",
        phone1: values.phone1 ? values.phone1 : "",
        phone2: values.phone2 ? values.phone2 : "",
        legalAddress: values.legalAddress ? values.legalAddress : "",
        address1: values.address1 ? values.address1 : "",
        address2: values.address2 ? values.address2 : "",
        imageUrl: "",
      })
      .then(async (response) => {
        if (response.status === 200) {
          window.scrollTo(0, 0);
          setRegistrationComplited(true);
        }
      })
      .catch((error) => {
        setLoginError(error.response.data);
      });
  };

  return (
    <Layout>
      <SEO
        pageProps={{
          title: "Sign Up",
          thumbnail: "",
          url: window.location,
        }}
      />
      <Content
        style={{
          margin: "auto",
          padding: 24,
          background: "#fff",
          minHeight: "100vh",
          borderRadius: 12,
          width: "100%"
        }}
      >
        <Row gutter={16} justify="center">
          <div className="footer-logo">
            <img
              src={
                information &&
                process.env.REACT_APP_API_URL + "/" + information.logoUrl
              }
              alt="logo"
            />
          </div>
          <Divider />
        </Row>
        <Typography>
          <Title>{t("SignUp.SignUp")}</Title>
          <Row type="flex" justify="center" gutter={32} align="middle">
            {!registrationComplited ? (
              <Form
                {...formItemLayout}
                form={form}
                name="signup"
                onFinish={onFinish}
                style={{ width: "80%" }}
                scrollToFirstError
              >
                <Form.Item
                  name="username"
                  label={t("SignIn.Username")}
                  rules={[
                    {
                      required: true,
                      message: t("SignIn.UsernameMessage"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="businessName"
                  label={t("SignUp.BusinessName")}
                  rules={[
                    {
                      required: true,
                      message: t("SignUp.BusinessNameMessage"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="Name" label={t("SignUp.AltName")}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="TIN"
                  label={t("SignUp.TIN")}
                  rules={[
                    {
                      required: true,
                      message: t("SignUp.TINMessage"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={t("SignIn.Email")}
                  rules={[
                    {
                      type: "email",
                      message: t("SignIn.EmailValidationMessage"),
                    },
                    {
                      required: true,
                      message: t("SignIn.EmailMessage"),
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={t("SignIn.Password")}
                  rules={[
                    {
                      required: true,
                      message: t("SignIn.PasswordMessage"),
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label={t("SignUp.ComfirmPassword")}
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: t("SignUp.ComfirmPasswordMessage"),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(t("SignUp.PasswordsNotMatch"));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
                <Form.Item
                  name="country"
                  label={t("SignUp.Country")}
                  rules={[
                    {
                      required: true,
                      message: t("SignUp.CountryMessage"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="city"
                  label={t("SignUp.City")}
                  rules={[
                    {
                      required: true,
                      message: t("SignUp.CityMessage"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="address1"
                  label={t("SignUp.Address")}
                  rules={[
                    {
                      required: true,
                      message: t("SignUp.AddressMessage"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="address2" label={t("SignUp.AltAddress")}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="phone1"
                  label={t("Order.PhoneNumber")}
                  rules={[
                    {
                      required: true,
                      message: t("Order.PhoneNumberMessage"),
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
                <Form.Item name="phone2" label={t("SignUp.AltPhoneNumber")}>
                  <Input
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(t("SignUp.ShouldAcceptAgreement")),
                    },
                  ]}
                  {...tailFormItemLayout}
                >
                  <Checkbox>
                    {t("SignUp.HaveReadAgreementFirst")}
                    <Link to="">{t("SignUp.HaveReadAgreementLast")}</Link>
                  </Checkbox>
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit">
                    {t("SignUp.SignUp")}
                  </Button>
                </Form.Item>
                {Object.values(loginError).length > 0 ? (
                  <Alert message={loginError.message} type="error" />
                ) : (
                  ""
                )}
              </Form>
            ) : (
              <Result
                status="success"
                title={t("SignUp.Complited")}
                subTitle={t("SignUp.Complited2")}
              />
            )}
          </Row>
          <Divider />
          <Row justify="center">
            <Link to="/">
              <Button type="danger">
                {registrationComplited
                  ? t("Global.BackToHome")
                  : t("Global.Cancel")}
              </Button>
            </Link>
          </Row>
        </Typography>
      </Content>
    </Layout>
  );
};

export default SignUp;
