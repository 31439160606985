import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Layout,
  Divider,
  Alert,
  Result,
} from "antd";
import api from "./../Api";
import { useTranslation } from "react-i18next";
import { LockOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Content } = Layout;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const ResetPassword = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [information, setInformation] = useState();
  const [loginError, setLoginError] = useState({});
  const [registrationComplited, setRegistrationComplited] = useState(false);
  const match = useRouteMatch("/reset-password/:slug");
  const history = useHistory();
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    const getInformation = async () => {
      await axios
      .get(process.env.REACT_APP_API_RESET_PASSWORD_URL + match.params.slug)
      .then(async (res) => {
        setShowData(true);
      })
      .catch((err) => {
        history.push("/404");
      });
      await api
        .informations()
        .get()
        .then((response) => setInformation(response.data[0]));
    };
    getInformation();
  }, [history, match.params.slug]);

  const onFinish = async (values) => {
    await axios
      .post(process.env.REACT_APP_API_RESET_PASSWORD_URL, {
        newPasswordToken: match.params.slug,
        newPassword: values.password,
      })
      .then((response) => {
        if (response.status === 200) {
          setRegistrationComplited(true);
        }
      })
      .catch((error) => {
        setLoginError(error.response.data);
      });
  };

  return (
    <Layout>
      <Content
        style={{
          margin: "24px 16px 24px 16px",
          padding: 24,
          background: "#fff",
          minHeight: "100vh",
          borderRadius: 12,
          visibility: showData ? "visible" : "hidden"
        }}
      >
        <Row gutter={16} justify="center">
          <div className="footer-logo">
            <img
              src={
                information &&
                process.env.REACT_APP_API_URL + "/" + information.logoUrl
              }
              alt="logo"
            />
          </div>
          <Divider />
        </Row>
        <Typography>
          <Title>{t("SignIn.ResetPassword")}</Title>
          <Row type="flex" justify="center" gutter={32} align="middle">
            {!registrationComplited ? (
              <Form
                {...formItemLayout}
                form={form}
                name="signup"
                onFinish={onFinish}
                style={{ width: "80%" }}
                scrollToFirstError
              >
                <Form.Item
                  name="password"
                  label={t("SignIn.NewPassword")}
                  rules={[
                    {
                      required: true,
                      message: t("SignIn.PasswordMessage"),
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label={t("SignUp.ComfirmPassword")}
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: t("SignUp.ComfirmPasswordMessage"),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(t("SignUp.PasswordsNotMatch"));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit">
                    {t("SignIn.ResetPassword")}
                  </Button>
                </Form.Item>
                {Object.values(loginError).length > 0 ? (
                  <Alert message={loginError.message} type="error" />
                ) : (
                  ""
                )}
              </Form>
            ) : (
              <Result
                status="success"
                title={t("SignUp.ResetPasswordComplited")}
                subTitle={t("SignUp.ResetPasswordComplited2")}
              />
            )}
          </Row>
          <Divider />
          <Row justify="center">
            <Link to="/">
              <Button type="danger">
                {registrationComplited
                  ? t("Global.BackToHome")
                  : t("Global.Cancel")}
              </Button>
            </Link>
          </Row>
        </Typography>
      </Content>
    </Layout>
  );
};

export default ResetPassword;
