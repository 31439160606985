import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FilterFilled, MenuOutlined } from "@ant-design/icons";
import api from "./../Api";
import { Layout, Menu, Button, Input, Modal, Avatar, Divider } from "antd";
import Cart from "./Cart";
import ResultForm from "../Components/ResultForm";
import { useTranslation } from "react-i18next";
import Headroom from "react-headroom";
import { PostOrPageTitle } from "../Common";
import Languages from "./Languages.json";
import logo from "../Images/logo.svg";
import { ValidateToken } from "../Common";
import { UserOutlined } from "@ant-design/icons";
import SignIn from "../Account/SignIn";

const { Header } = Layout;
const { Search } = Input;

const usePages = () => {
  const [pages, setPages] = useState([]);
  const [customer, setCustomer] = useState();

  useEffect(() => {
    const getPages = async () => {
      const { data } = await api.pages().get();
      setPages(
        data
          .filter((x) => x.isInNavigation === true)
          .sort((a, b) => a.ordering - b.ordering)
      );
    };
    const getCompanyData = async () => {
      if (ValidateToken()) {
        await api
          .customer()
          .get()
          .then((res) => {
            setCustomer(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    getCompanyData();
    getPages();
  }, []);

  return {
    pages,
    customer,
  };
};

const HeaderMobile = ({ from, cartCount, setCartCount }) => {
  const [searchValue, setSearchValue] = useState("");
  const [visibleFilter, setVisibleFilter] = useState(false);
  const { pages, customer } = usePages();
  let history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryId = params.get("categoryId");
  const { t, i18n } = useTranslation();
  const [modalVisibility, setModalVisibility] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [hamburger, setHamburger] = useState(false);

  const handleLangSwitch = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleOnSearch = (value) => {
    if (categoryId === null) {
      history.push({
        pathname: "/result",
        search: `?search=${value}`,
      });
    } else {
      history.push({
        pathname: "/result",
        search: `&search=${value}`,
      });
    }
    setSearchValue(value);
    window.location.reload();
  };

  const handleOpenFilter = () => {
    if (from !== "ResultPage") {
      setVisibleFilter(!visibleFilter);
    }
  };
  const handleCancel = () => {
    setVisibleFilter(false);
  };

  const pushToAccount = () => {
    history.push({
      pathname: "/account",
    });
  };

  const showModal = () => {
    setModalVisibility(true);
  };

  const handleModalOk = (e) => {
    setModalVisibility(false);
  };

  const handleModalCancel = (e) => {
    setModalVisibility(false);
  };

  const handleHamburger = () => {
    setHamburger(!hamburger);
  };

  return (
    <Headroom style={{ zIndex: "99" }}>
      <div className="mobile-header">
        <Header>
          <div>
            <Button
              type="primary"
              shape="round"
              icon={<MenuOutlined />}
              size="large"
              onClick={handleHamburger}
              style={{ textTransform: "uppercase", marginLeft: 6 }}
            ></Button>
            <Menu
              style={{
                display: hamburger ? "block" : "none",
              }}
              className="mobile-menu"
              mode="vertical"
              defaultSelectedKeys={from !== "ProductPage" ? [`${from}`] : [""]}
              selectedKeys={[`${from}`]}
            >
              <Menu.Item key={0}>
                <Link to="/">{t("Home.1")}</Link>
              </Menu.Item>
              {pages.map((page) => (
                <Menu.Item key={page.id}>
                  <Link to={`/page/${page.slug}`}>
                    {PostOrPageTitle(page, i18n.language)}
                  </Link>
                </Menu.Item>
              ))}
              <Menu.Item key={-1}>
                <div className="lang-switch">
                  {Languages.map((language) => {
                    return (
                      <Button
                        type="dashed"
                        size="small"
                        onClick={() => handleLangSwitch(language.code)}
                        className={
                          language.code === i18n.language
                            ? "active-language"
                            : ""
                        }
                        key={language.id}
                      >
                        <img
                          alt={language.code}
                          width="20"
                          src={apiUrl + `/uploads/${language.code}.svg`}
                        />
                        {language.name}
                      </Button>
                    );
                  })}
                </div>
              </Menu.Item>
            </Menu>
          </div>
          <div style={{ margin: "auto" }}>
            <Link to="/">
              <div className="logo" style={{ height: "100%" }}>
                 <img src={logo} alt="logo" />
              </div>
            </Link>
          </div>
          <div style={{ display: "inline-flex", position: "relative" }}>
            {ValidateToken() ? (
              <Cart
                from={from}
                cartCount={cartCount}
                setCartCount={setCartCount}
              />
            ) : (
              ""
            )}
          </div>
          <Modal
            title={t("SignIn.SignIn")}
            visible={modalVisibility}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
            className="sign-in-modal"
          >
            <SignIn isModal={true} />
          </Modal>
        </Header>
        <div className="search-filter-mobile">
          <Search
            placeholder={searchValue ? searchValue : t("NavBar.SearchText")}
            onSearch={handleOnSearch}
            size="default"
            className="search-mobile"
          />
          <div onClick={handleOpenFilter} className="mobile-categories">
            {t("Sider.Headline")}
            <FilterFilled
              style={{
                color: "white",
                fontSize: "20px",
                marginRight: 0,
                marginLeft: 0,
                opacity: `${visibleFilter ? 0.3 : 1}`,
              }}
            />
          </div>
          {ValidateToken() ? (
            <div style={{ display: "inline-flex" }}>
              {customer ? (
                <>
                  <Divider type="vertical" style={{ height: 32 }} />
                  <Avatar onClick={pushToAccount} src={customer.imageUrl} />
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            <UserOutlined className="user-login-icon" onClick={showModal} />
          )}
        </div>
        <div className="fade-in">
          <Modal visible={visibleFilter} onCancel={handleCancel} footer={null}>
            <ResultForm />
          </Modal>
        </div>
      </div>
    </Headroom>
  );
};

export default HeaderMobile;
