import React from "react";
import { Link } from "react-router-dom";
import { Menu, Layout } from "antd";
import { useTranslation } from "react-i18next";
import { CategoryName } from "../Common";

const { Sider } = Layout;
const { SubMenu } = Menu;

/* const API_URL = process.env.REACT_APP_API_URL;
 */
const SiderMenu = ({ categories, subCategories, showCategoryMenu }) => {
  const { i18n } = useTranslation();

  return (
    <div style={{ display: showCategoryMenu ? "block" : "none" }}>
      <Sider
        className="sider-menu"
        width={"240"}
      >
        <Menu
          mode="vertical"
          defaultSelectedKeys={["-1"]}
          style={{ paddingTop: 16, height: "auto" }}
        >
          {categories &&
            categories.map((cat) =>
              subCategories.some((x) => x.parentId === cat.id) ? (
                <SubMenu
                  key={cat.id}
                  title={
                    <Link
                      to={{
                        pathname: "/result",
                        search: `?category=${cat.id}`,
                      }}
                    >
                      {CategoryName(cat, i18n.language)}
                    </Link>
                  }
                >
                  {subCategories.map((sub) =>
                    cat.id === sub.parentId &&
                    subCategories.filter((x) => x.parentId === sub.id)
                      .length === 0 ? (
                      <Menu.Item key={sub.id}>
                        <Link
                          to={{
                            pathname: "/result",
                            search: `?category=${sub.id}`,
                          }}
                        >
                          {CategoryName(sub, i18n.language)}
                        </Link>
                      </Menu.Item>
                    ) : cat.id === sub.parentId &&
                      subCategories.some((x) => x.parentId === sub.id) ? (
                      <SubMenu
                        key={sub.id}
                        title={
                          <Link
                            to={{
                              pathname: "/result",
                              search: `?category=${sub.id}`,
                            }}
                          >
                            {CategoryName(sub, i18n.language)}
                          </Link>
                        }
                      >
                        {subCategories.map((subChild) =>
                          cat.id === sub.parentId &&
                          subChild.parentId === sub.id ? (
                            <Menu.Item key={subChild.id}>
                              <Link
                                to={{
                                  pathname: "/result",
                                  search: `?category=${subChild.id}`,
                                }}
                              >
                                {CategoryName(subChild, i18n.language)}
                              </Link>
                            </Menu.Item>
                          ) : (
                            ""
                          )
                        )}
                      </SubMenu>
                    ) : (
                      ""
                    )
                  )}
                </SubMenu>
              ) : (
                <Menu.Item key={cat.id}>
                  <Link
                    to={{
                      pathname: "/result",
                      search: `?category=${cat.id}`,
                    }}
                  >
                    {CategoryName(cat, i18n.language)}
                  </Link>
                </Menu.Item>
              )
            )}
        </Menu>
      </Sider>
      {/*         <Col xs={0} lg={24}>
          {banners &&
          banners.length > 0 &&
          banners.some((x) => x.bannerName === "leftBanner") ? (
            <a
              href={
                banners.find((x) => x.bannerName === "leftBanner")
                  .additionalLink
              }
            >
              <img
                src={banners.find((x) => x.bannerName === "leftBanner").url}
                alt="leftBanner"
                className="leftTowerBanner"
              />
            </a>
          ) : (
            ""
          )}
        </Col> */}
    </div>
  );
};

export default SiderMenu;
