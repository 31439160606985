import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import api from "./../Api";
import { Typography, Row, Col, Layout, Spin } from "antd";
import NavBar from "../Components/NavBar";
import HeaderMobile from "./../Components/HeaderMobile";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import Calculator from "../Components/Calculator";
import SEO from "../Components/SEO";
import { EditorText, PostOrPageTitle, ValidateToken } from "../Common";
import { LoadingOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const Page = () => {
  const [page, setPage] = useState({});
  const [cartCount, setCartCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  const match = useRouteMatch("/page/:slug");
  const history = useHistory();

  useEffect(() => {
    const fetchPages = async () => {
      await api
        .pages(match.params.slug)
        .get()
        .then((response) =>
          response.data.length === 0
            ? history.push("/404")
            : setPage(response.data[0])
        );
      setLoading(false);
    };
    const getCartData = async () => {
      if (ValidateToken()) {
        await api
          .cart()
          .get()
          .then((res) => {
            setCartCount(res.data.length);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    window.scrollTo(0, 0);
    getCartData();
    fetchPages();
  }, [history, match.params.slug]);

  return (
    <Spin
      indicator={
        <LoadingOutlined style={{ fontSize: 24, color: "#2bbef9" }} spin />
      }
      spinning={loading}
    >
      <HeaderMobile cartCount={cartCount} setCartCount={setCartCount} />
      <NavBar
        from={page.id}
        cartCount={cartCount}
        setCartCount={setCartCount}
      />
      <Layout style={{maxWidth: "1170px", margin: "auto"}}>
        <Content
          style={{ minHeight: "100vh", margin: 12 }}
        >
          <SEO
            pageProps={{
              title: PostOrPageTitle(page, i18n.language),
              thumbnail: page.imageUrl ? page.imageUrl : "",
              url: window.location,
            }}
          />
          <Row type="flex" justify="center" gutter={32} align="middle">
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              {page.imageUrl ? (
                <div
                  style={{
                    backgroundImage: `url(${page.imageUrl})`,
                    backgroundAttachment: page.imageIsFixed
                      ? "fixed"
                      : "scroll",
                    backgroundPosition: page.imageIsFixed ? "bottom" : "center",
                  }}
                  className="blog-header"
                >
                  <Title className="blog-title">
                    {PostOrPageTitle(page, i18n.language)}
                  </Title>
                </div>
              ) : (
                ""
              )}
              <Typography>
                <Title>{PostOrPageTitle(page, i18n.language)}</Title>
                <Row type="flex" justify="center" gutter={32} align="middle">
                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    {match.params.slug === "credit" ? <Calculator /> : ""}
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    lg={{ span: match.params.slug === "credit" ? 12 : 24 }}
                  >
                    <Paragraph className="ck-content">
                      {ReactHtmlParser(
                        EditorText(page, i18n.language).editorText
                      )}
                    </Paragraph>
                  </Col>
                </Row>
              </Typography>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Spin>
  );
};

export default Page;
