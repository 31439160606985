import React, { useState, useEffect } from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import api from "./../Api";
import { LoadingOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import {
  Typography,
  Row,
  Col,
  Layout,
  Button,
  InputNumber,
  Divider,
  Spin,
  Tag,
  Card,
  message,
} from "antd";
import NavBar from "../Components/NavBar";
import HeaderMobile from "./../Components/HeaderMobile";
import ImageGallery from "react-image-gallery";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import Relations from "./Relations";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  VKShareButton,
  ViberShareButton,
  EmailShareButton,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTelegram,
  faTwitter,
  faWhatsapp,
  faVk,
  faViber,
} from "@fortawesome/free-brands-svg-icons";
import ResultForm from "../Components/ResultForm";
import SEO from "../Components/SEO";
import {
  ValidateToken,
  ProductName,
  CategoryName,
  ManufacturerName,
  EditorText,
  GetDiscountedPriceForProductPage,
  GetDiscountedPriceForCart,
} from "./../Common";
import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";

const { Title, Paragraph } = Typography;
const API_URL = process.env.REACT_APP_API_URL;
const { Content } = Layout;

//get categories and manufacturers
const CategoryTag = ({ catId, manufId, info, languageCode }) => {
  const [category, setCategory] = useState("");
  const [manufacturer, setManufacturer] = useState("");

  useEffect(() => {
    if (catId !== undefined) {
      const getCategory = async () => {
        const { data } = await api.categories(catId).get();
        setCategory(CategoryName(data[0], languageCode));
      };
      getCategory();
    }
    if (manufId !== undefined) {
      const getManufacturer = async () => {
        const { data } = await api.manufacturers(manufId).get();
        setManufacturer(ManufacturerName(data[0], languageCode));
      };
      getManufacturer();
    }
  }, [catId, manufId, languageCode]);

  return (
    <Row type="flex" justify="space-between" align="middle">
      <div>
        {category !== "" && (
          <Link
            style={{ color: "white" }}
            to={{
              pathname: "/result",
              search: `?category=${catId}`,
            }}
          >
            <Tag hidden={!catId} className="productTags" color="#faa535">
              {category}
            </Tag>
          </Link>
        )}
        {manufacturer !== "" && (
          <Link
            style={{ color: "white" }}
            to={{
              pathname: "/result",
              search: `?manufacturer=${manufId}`,
            }}
          >
            <Tag className="productTags" color="#001529">
              {manufacturer}
            </Tag>
          </Link>
        )}
      </div>
      <div className="manufacturerLogoInProductPage">
        {info &&
        info.showManufacturerLogoInProductCard &&
        manufacturer &&
        manufacturer.imageUrl !== "" ? (
          <img
            width="100%"
            src={manufacturer.imageUrl}
            alt={ManufacturerName(manufacturer, languageCode)}
          />
        ) : (
          ""
        )}
      </div>
    </Row>
  );
};

const BottomBanner = () => {
  const [banners, setBanners] = useState();

  useEffect(() => {
    const getBanners = async () => {
      const imagesUrls = [];
      await api
        .banners()
        .get()
        .then((response) =>
          response.data.forEach((img) => {
            if (img.bannerName === "bottomBanner") {
              imagesUrls.push({
                bannerName: img.bannerName,
                url: `${API_URL}/${img.url}`,
                additionalLink: img.additionalLink,
              });
            }
          })
        );
      setBanners(imagesUrls);
    };
    getBanners();
  }, []);

  return (
    <Row type="flex" justify="center" align="middle">
      <Col span={18}>
        {banners &&
        banners.length > 0 &&
        banners.some((x) => x.bannerName === "bottomBanner") ? (
          <a
            href={
              banners.find((x) => x.bannerName === "bottomBanner")
                .additionalLink
            }
          >
            <img
              src={banners.find((x) => x.bannerName === "bottomBanner").url}
              alt="bottomBanner"
              className="bottomBanner"
            />
          </a>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
};

const Images = (product) => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    const fetchImages = async () => {
      if (product !== undefined) {
        const imagesUrls = [];
        await api
          .productImages(product)
          .get()
          .then((response) =>
            response.data.forEach((image) => {
              imagesUrls.push({
                original: `${API_URL}/${image.url}`,
                thumbnail: `${API_URL}/${image.url}`,
              });
            })
          );
        setImages((images) => ({ ...images, fileList: imagesUrls }));
        window.scrollTo(0, 0);
      }
    };
    fetchImages();
  }, [product]);
  return images;
};

const Product = (props) => {
  const [product, setProduct] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [prices, setPrices] = useState([]);
  const [info, setInfo] = useState();
  const [cartData, setCartData] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [orderedProducts, setOrderedProducts] = useState([]);
  const [addToCartLoading, setAddToCartLoading] = useState(false);

  useEffect(() => {
    const getInfo = async () => {
      await api
        .informations()
        .get()
        .then((res) => setInfo(res.data))
        .catch((err) => {
          console.log(err);
        });
    };
    const getCartData = async () => {
      if (ValidateToken()) {
        await api
          .cart()
          .get()
          .then((res) => {
            setCartData(res.data);
            setCartCount(res.data.length);
            let orderedPrds = [];
            res.data.forEach((element) => {
              orderedPrds.push({
                productId: element.productId,
                quantity: Number(element.quantity),
              });
            });
            setOrderedProducts(orderedPrds);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    getInfo();
    getCartData();
  }, []);

  const match = useRouteMatch("/product/:slug");
  const { t, i18n } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    let tmpArray = [];
    const fetchProducts = async () => {
      if (
        props.location.from !== undefined &&
        props.location.from.fromRelations === true
      ) {
        setLoading(true);
      }
      await api
        .products(match.params.slug)
        .get()
        .then(async (response) => {
          if (response.data.length === 0) {
            history.push("/404");
          } else {
            setProduct(response.data[0]);
            tmpArray.push(response.data[0]);
            if (
              response.data[0].relations &&
              response.data[0].relations.length > 0
            ) {
              await api
                .relatedProducts(response.data[0].relations)
                .get()
                .then((res) => {
                  setRelatedProducts(
                    [...tmpArray, ...res.data].sort((a, b) =>
                      a.weight > b.weight ? 1 : b.weight > a.weight ? -1 : 0
                    )
                  );
                });
            }
            if (ValidateToken()) {
              await api
                .prices(response.data[0].id)
                .get()
                .then((res) => setPrices(res.data))
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        });
    };
    fetchProducts();
  }, [history, match.params.slug, props.location.from]);

  const images = Images(product.id);
  const editorTexts = EditorText(product, i18n.language);

  const onQuantityChange = async (value, productId) => {
    let tmpRows = [...orderedProducts];
    if (tmpRows.some((x) => x.productId === productId)) {
      tmpRows.find((x) => x.productId === productId).quantity = value;
    } else {
      tmpRows.push({ productId: productId, quantity: value });
    }
    setOrderedProducts(tmpRows);
  };

  const onCartQuantityChange = (product, prices) => {
    setAddToCartLoading(true);
    let uid = "";
    if (Cookie.get("jwtToken")) {
      let decoded = jwt_decode(Cookie.get("jwtToken"));
      uid = decoded.id;
    }
    if (uid !== "") {
      let data = {
        productId: Number(product.id),
        quantity:
          orderedProducts.find((x) => x.productId === product.id)?.quantity ??
          0,
        customerUid: uid,
        price: product.byOrder
          ? 0
          : GetDiscountedPriceForCart(product.id, prices),
      };
      api
        .addToCart()
        .post(data)
        .then((res) => {
          setCartData(res.data);
          setCartCount(res.data.length);
          let orderedPrds = [];
          res.data.forEach((element) => {
            orderedPrds.push({
              productId: element.productId,
              quantity: Number(element.quantity),
            });
          });
          setOrderedProducts(orderedPrds);
          message.success(`${t("ProductPage.CartUpdated")}`, 2);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(setTimeout(() => setAddToCartLoading(false), 800));
    }
  };

  const onImageLoad = (e) => {
    setLoading(false);
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <LoadingOutlined style={{ fontSize: 24, color: "#2bbef9" }} spin />
      }
    >
      <HeaderMobile
        from="ProductPage"
        cartCount={cartCount}
        setCartCount={setCartCount}
      />
      <NavBar
        from="ProductPage"
        cartCount={cartCount}
        setCartCount={setCartCount}
      />
      <div id="product">
        <Layout>
          <Content
            style={{
              margin: "auto",
              padding: 24,
              background: "#fff",
              minHeight: "100vh",
              borderRadius: 12,
              width: "100%",
            }}
          >
            <div className="prodResForm">
              {info && info.showFilterInProductPage ? <ResultForm /> : ""}
            </div>
            <SEO
              pageProps={{
                title: ProductName(product, i18n.langugage),
                thumbnail:
                  images && images.fileList && images.fileList.length > 0
                    ? images.fileList[0].thumbnail
                    : "",
                url: window.location,
              }}
            />
            <Row type="flex" justify="center" gutter={32} align="top">
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <ImageGallery
                  items={images.fileList}
                  showPlayButton={false}
                  showBullets={false}
                  showFullscreenButton={false}
                  lazyLoad={true}
                  onImageLoad={onImageLoad}
                />
                {product.youtube ? (
                  <iframe
                    title="youtube"
                    src={product.youtube}
                    frameBorder="0"
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Typography>
                  <Title>{ProductName(product, i18n.language)}</Title>
                  <CategoryTag
                    catId={product.categoryId}
                    manufId={product.manufacturerId}
                    info={info}
                    languageCode={i18n.language}
                  />
                  <Divider />
                  <Row type="flex" justify="space-between" align="middle">
                    {relatedProducts && relatedProducts.length > 0 ? (
                      <div className="weight-or-volume">
                        <p>{t("ProductPage.PackagesInfo")}</p>
                        <Row type="flex" justify="start" align="middle">
                          {relatedProducts.map((item) => (
                            <Link
                              key={item.id}
                              to={{
                                pathname: `/product/${item.slug}`,
                                productProps: {
                                  id: item.id,
                                },
                                from: { fromRelations: true },
                              }}
                            >
                              <div>
                                <span
                                  className={
                                    product.id === item.id
                                      ? "cube selected-cube"
                                      : "cube"
                                  }
                                ></span>
                                {item.weight && item.weight > 0
                                  ? item.weight + t("ProductPage.Gramm") + "/"
                                  : item.volume && item.volume > 0
                                  ? item.volume + t("ProductPage.Liter") + "/"
                                  : ""}
                                {item.quantityInBox +
                                  " " +
                                  t("ProductPage.Pcs")}
                              </div>
                            </Link>
                          ))}
                        </Row>
                      </div>
                    ) : (
                      <div className="weight-or-volume">
                        <p>{t("ProductPage.PackagesInfo")}</p>
                        <Row type="flex" justify="start" align="middle">
                          <div>
                            <span className="cube selected-cube"></span>
                            {product.weight && product.weight > 0
                              ? product.weight + t("ProductPage.Gramm") + "/"
                              : product.volume && product.volume > 0
                              ? product.volume + t("ProductPage.Liter") + "/"
                              : ""}
                            {product.quantityInBox + " " + t("ProductPage.Pcs")}
                          </div>
                        </Row>
                      </div>
                    )}
                  </Row>
                  <Row type="flex" justify="start" align="middle">
                    <div style={{ marginBottom: 6, width: "100%" }}>
                      <Title level={2} style={{ marginBottom: 0 }}>
                        {!product.byOrder
                          ? GetDiscountedPriceForProductPage(
                              product.id,
                              prices,
                              t
                            )
                          : t("SelectedProducts.ByOrder")}
                      </Title>
                      <div
                        style={{
                          color: "rgba(0, 0, 0, 0.45)",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                      >
                        {product.quantityInBox && prices && prices.length > 0 && prices[0].unitPrice > 0
                          ? prices[0].unitPrice + " " + t("ProductPage.HT") + " €"
                          : ""}
                        <sub>
                          {ValidateToken() ? "/" : ""}
                          {product.quantityInBox && prices && prices.length > 0 && prices[0].unitPrice > 0
                            ? t("ProductPage.UnitPrice")
                            : ""}
                        </sub>
                      </div>
                    </div>
                    <div>
                      {product.inStock ? (
                        <Tag style={{ marginRight: 0 }} color="green">
                          {t("ProductPage.InStock")}
                        </Tag>
                      ) : (
                        <Tag style={{ marginRight: 0 }} color="red">
                          {t("ProductPage.OutOfStock")}
                        </Tag>
                      )}
                    </div>
                  </Row>
                  <Divider />
                  <Paragraph className="ck-content">
                    {ReactHtmlParser(editorTexts.editorText)}
                  </Paragraph>
                  <Paragraph className="ck-content">
                    {ReactHtmlParser(editorTexts.compositionText)}
                  </Paragraph>
                  <Paragraph className="ck-content">
                    {ReactHtmlParser(editorTexts.boxText)}
                    <div>
                      {product.barcode
                        ? t("ProductPage.BarCode") + ": " + product.barcode
                        : ""}
                    </div>
                    <div>
                      {product.boxBarcode
                        ? t("ProductPage.BoxBarCode") +
                          ": " +
                          product.boxBarcode
                        : ""}
                    </div>
                  </Paragraph>
                  <Paragraph className="ck-content">
                    {ReactHtmlParser(editorTexts.periodText)}
                  </Paragraph>
                </Typography>
                <Divider dashed />
                {ValidateToken() ? (
                  <Row type="flex" justify="center" gutter={6} align="middle">
                    <Col>
                      <InputNumber
                        min={0}
                        value={
                          orderedProducts.some(
                            (x) => x.productId === product.id
                          )
                            ? Number(
                                orderedProducts.find(
                                  (x) => x.productId === product.id
                                ).quantity
                              )
                            : 0
                        }
                        onChange={async (value) =>
                          await onQuantityChange(value, product.id)
                        }
                      />
                    </Col>
                    <Col>
                      <Button
                        type="dashed"
                        icon={<ShoppingCartOutlined />}
                        size="large"
                        disabled={!product.inStock}
                        loading={addToCartLoading}
                        onClick={() => onCartQuantityChange(product, prices)}
                      ></Button>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Row
                  className="share_buttons"
                  type="flex"
                  justify="center"
                  gutter={32}
                  align="middle"
                >
                  <Card bordered={false} title={t("ProductPage.Share")}>
                    <FacebookShareButton
                      children={<FontAwesomeIcon icon={faFacebook} />}
                      url={window.location.href}
                    />
                    <TwitterShareButton
                      children={<FontAwesomeIcon icon={faTwitter} />}
                      url={window.location.href}
                    />
                    <TelegramShareButton
                      children={<FontAwesomeIcon icon={faTelegram} />}
                      url={window.location.href}
                    />
                    <WhatsappShareButton
                      children={<FontAwesomeIcon icon={faWhatsapp} />}
                      url={window.location.href}
                    />
                    <VKShareButton
                      children={<FontAwesomeIcon icon={faVk} />}
                      url={window.location.href}
                    />
                    <ViberShareButton
                      children={<FontAwesomeIcon icon={faViber} />}
                      url={window.location.href}
                    />
                    <EmailShareButton
                      children={<FontAwesomeIcon icon={faEnvelope} />}
                      url={window.location.href}
                    />
                  </Card>
                </Row>
              </Col>
            </Row>
          </Content>
          <Divider />
          <Row type="flex" justify="center" align="middle">
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              {product.categoryId ? (
                <>
                  <Relations
                    categoryId={product.categoryId}
                    exceptId={product.id}
                    cartData={cartData}
                    setCartData={setCartData}
                    setCartCount={setCartCount}
                    cartCount={cartCount}
                    setSpinning={setLoading}
                  />
                  <Divider />
                  <BottomBanner />
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Layout>
      </div>
    </Spin>
  );
};

export default Product;
