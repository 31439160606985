import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "./../Api";
import {
  Row,
  Col,
  Layout,
  Spin,
  Card,
  Typography,
  Button,
  Divider,
} from "antd";
import NavBar from "../Components/NavBar";
import HeaderMobile from "./../Components/HeaderMobile";
import SEO from "../Components/SEO";
import { useTranslation } from "react-i18next";
import { ClockCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { PostOrPageTitle, PostOrPageExcerpt } from "../Common";

const { Content } = Layout;
const { Meta } = Card;
const { Title } = Typography;

const BlogArchive = ({ from }) => {
  const [archive, setArchive] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cartCount, setCartCount] = useState(
    localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart")).length
      : 0
  );
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchArchives = async () => {
      await api
        .blog()
        .get()
        .then((response) => setArchive(response.data));
      setLoading(false);
      window.scrollTo(0, 0);
    };
    fetchArchives();
  }, []);

  return from !== "App" ? (
    <Spin
      indicator={
        <LoadingOutlined style={{ fontSize: 24, color: "#2bbef9" }} spin />
      }
      spinning={loading}
    >
      <HeaderMobile cartCount={cartCount} setCartCount={setCartCount} />
      <NavBar
        from="BlogArchivePage"
        cartCount={cartCount}
        setCartCount={setCartCount}
      />
      <Layout>
        <SEO
          pageProps={{
            title: "Blog Archive",
            thumbnail: "",
            url: window.location,
          }}
        />
        <Content
          style={{ margin: "24px 24px", maxWidth: "100%", minHeight: "100vh" }}
        >
          <Typography>
            <Title>{t("Blog.ArchiveTitle")}</Title>
          </Typography>
          <Row type="flex" justify="start" gutter={32} align="top">
            {archive
              .filter((x) => x.imageUrl)
              .map((item, index) =>
                index <= 8 ? (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    lg={{ span: 8 }}
                    style={{ marginBottom: 12 }}
                    key={item.id}
                  >
                    <Link
                      to={{
                        pathname: `/blog/${item.slug}`,
                        blogProps: {
                          id: item.id,
                        },
                      }}
                    >
                      <Card
                        bordered={false}
                        className="archive-card"
                        cover={
                          <img
                            alt={item.name ? item.name[0].text : ""}
                            src={item.imageUrl}
                          />
                        }
                        actions={[
                          <p key="date">
                            <ClockCircleOutlined />
                            {" " +
                              new Date(item.createdAt).getDate() +
                              "/" +
                              (new Date(item.createdAt).getMonth() + 1) +
                              "/" +
                              new Date(item.createdAt).getFullYear()}
                          </p>,
                        ]}
                      >
                        <Meta
                          title={PostOrPageTitle(item, i18n.language)}
                          description={PostOrPageExcerpt(item, i18n.language)}
                        />
                      </Card>
                    </Link>
                  </Col>
                ) : (
                  ""
                )
              )}
          </Row>
        </Content>
      </Layout>
    </Spin>
  ) : (
    <div>
      <Typography>
        <Title>{archive.length > 0 ? t("Blog.ArchiveTitle") : ""}</Title>
      </Typography>
      <Row type="flex" justify="start" gutter={32} align="top">
        {archive
          .filter((x) => x.imageUrl)
          .map((item, index) =>
            index <= 5 ? (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: 8 }}
                style={{ marginBottom: 12 }}
                key={item.id}
              >
                <Link
                  to={{
                    pathname: `/blog/${item.slug}`,
                    blogProps: {
                      id: item.id,
                    },
                  }}
                >
                  <Card
                    bordered={false}
                    className="archive-card"
                    cover={
                      <img
                        alt={item.name ? item.name[0].text : ""}
                        src={item.imageUrl}
                      />
                    }
                    actions={[
                      <p key="date">
                        <ClockCircleOutlined />
                        {" " +
                          new Date(item.createdAt).getDate() +
                          "/" +
                          (new Date(item.createdAt).getMonth() + 1) +
                          "/" +
                          new Date(item.createdAt).getFullYear()}
                      </p>,
                    ]}
                  >
                    <Meta title={PostOrPageTitle(item, i18n.language)} />
                  </Card>
                </Link>
              </Col>
            ) : (
              ""
            )
          )}
      </Row>
      <Row type="flex" justify="center" gutter={32} align="middle">
        <Divider dashed />
        {archive.length > 0 ? (
          <Link
            to={{
              pathname: "/archive",
            }}
          >
            <Button type="primary" size="large">
              {t("Global.More")}
            </Button>
          </Link>
        ) : (
          ""
        )}
      </Row>
    </div>
  );
};

export default BlogArchive;
