import React from "react";
import Helmet from "react-helmet";

const SEO = ({ pageProps }) => {
  return (
    <Helmet>
      <title>{pageProps.title}</title>
      <meta property="og:title" content={pageProps.title} />
      <meta property="og:image" content={pageProps.thumbnail} />
      <meta property="og:url" content={pageProps.url} />
    </Helmet>
  );
};
export default SEO;
