import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import api from "./../Api";
import {
  Row,
  Col,
  Layout,
  Card,
  Spin,
  Select,
  Input,
  InputNumber,
  Checkbox,
  Form,
} from "antd";
import NavBar from "../Components/NavBar";
import { useTranslation } from "react-i18next";
import SEO from "./SEO";
import {
  ManufacturerName,
  ProductName,
  CategoryName,
  GetDiscountedPriceForCart,
  ValidateToken,
} from "./../Common";
import HeaderMobile from "./HeaderMobile";
import Products from "./Products";
import { LoadingOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Option, OptGroup } = Select;
const InputGroup = Input.Group;

const FetchProducts = () => {
  const [spinning, setSpinning] = useState(true);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryId = params.get("category");
  const manufacturerId = params.get("manufacturer");
  const minimumPrice = parseFloat(params.get("minimum"));
  const maximumPrice = parseFloat(params.get("maximum"));
  const [searchedText, setSearchedText] = useState(
    params.get("search") !== null ? params.get("search") : ""
  );
  const [selectedPrices, setSelectedPrices] = useState({
    minimum: minimumPrice !== null ? minimumPrice : 0,
    maximum: maximumPrice !== 0 ? maximumPrice : null,
  });
  const [selectedCategory, setSelectedCategory] = useState(
    categoryId !== null ? parseInt(categoryId) : 0
  );
  const [selectedManufacturer, setSelectedManufacturer] = useState(
    manufacturerId !== null ? parseInt(manufacturerId) : 0
  );
  const [parentCategories, setParentCategories] = useState([]);
  const [inStock, setInStock] = useState(
    params.get("inStock") !== null ? JSON.parse(params.get("inStock")) : true
  );
  const [byOrder, setByOrder] = useState(
    params.get("byOrder") !== null ? JSON.parse(params.get("byOrder")) : true
  );
  const [prices, setPrices] = useState([]);
  const { i18n } = useTranslation();
  const [info, setInfo] = useState();

  useEffect(() => {
    const getCategories = async () => {
      let parents = [];

      parents.push(selectedCategory);
      await api
        .categories("isActive")
        .get()
        .then((response) => {
          if (
            response.data.some(
              (x) => x.parentId === selectedCategory && x.parentId !== 0
            )
          ) {
            response.data
              .filter(
                (x) => x.parentId === selectedCategory && x.parentId !== 0
              )
              .map((item) => parents.push(item.id));
          }
          response.data
            .filter(
              (x) => parents.includes(x.parentId) && !parents.includes(x.id)
            )
            .map((item) => parents.push(item.id));
        });
      setParentCategories(parents);
    };
    getCategories();
  }, [selectedCategory]);

  useEffect(() => {
    const getPrices = async () => {
      if (ValidateToken()) {
        await api
          .prices()
          .get()
          .then((res) => setPrices(res.data))
          .catch((err) => {
            console.log(err);
          });
      }
    };

    const getInfo = async () => {
      const { data } = await api.informations().get();
      setInfo(data);
    };

    getPrices();
    getInfo();
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      let filter = {
        categoryId: selectedCategory,
        manufacturerId: selectedManufacturer,
      };
      await api
        .categoryRelatedProducts()
        .get({
          params: {
            categoryId: selectedCategory,
            manufacturerId: selectedManufacturer,
            searchText: searchedText,
          },
        })
        .then((response) => {
          let filtered = response.data.filter((item) => {
            if (!item.isActive) {
              return false;
            }
            if (!(item.inStock === inStock) && !byOrder) {
              return false;
            }
            if (!(item.byOrder === byOrder) && !inStock) {
              return false;
            }
            if (
              !ProductName(item, i18n.language)
                .toUpperCase()
                .includes(searchedText.toUpperCase())
            ) {
              return false;
            }
            for (let key in filter) {
              if (filter[key] !== 0) {
                if (
                  key === "categoryId" &&
                  (item[key] === undefined ||
                    (parentCategories.length !== 0
                      ? !parentCategories.includes(item[key])
                      : item[key] !== filter[key]))
                ) {
                  return false;
                }
                if (
                  key === "manufacturerId" &&
                  (item[key] === undefined || item[key] !== filter[key])
                ) {
                  return false;
                }
              }
              let newPrice = GetDiscountedPriceForCart(item.id, prices);
              if (
                selectedPrices.maximum !== null &&
                newPrice > 0 &&
                selectedPrices.maximum !== 0
              ) {
                if (
                  !item.byOrder &&
                  (newPrice < Number(selectedPrices.minimum) ||
                    newPrice > Number(selectedPrices.maximum))
                ) {
                  return false;
                }
              }
            }
            return true;
          });
          setProducts(filtered);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(setSpinning(false));
    };
    const getCategories = async () => {
      const { data } = await api
        .manufacturerCategories(selectedManufacturer)
        .get();
      setCategories(data);
      setSubCategories(
        data.filter((x) => x.parentId !== 0).sort((a, b) => a.code - b.code)
      );
    };
    const getManufacturers = async () => {
      const { data } = await api.manufacturers().get();
      setManufacturers(data);
    };
    getProducts();
    getCategories();
    getManufacturers();
  }, [
    categoryId,
    manufacturerId,
    selectedCategory,
    selectedManufacturer,
    selectedPrices.maximum,
    selectedPrices.minimum,
    searchedText,
    parentCategories,
    inStock,
    byOrder,
    prices,
    i18n,
  ]);

  return {
    products,
    categories,
    subCategories,
    manufacturers,
    spinning,
    categoryId,
    manufacturerId,
    setSelectedCategory,
    setSelectedManufacturer,
    setSelectedPrices,
    setSearchedText,
    setInStock,
    setByOrder,
    prices,
    info,
    setSpinning,
    selectedPrices,
  };
};

const Result = () => {
  const [cartCount, setCartCount] = useState(0);
  const [cartData, setCartData] = useState(0);
  useEffect(() => {
    const getCartData = async () => {
      if (ValidateToken()) {
        await api
          .cart()
          .get()
          .then((res) => {
            setCartData(res.data);
            setCartCount(res.data.length);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    getCartData();
  }, [setCartData, setCartCount]);

  const {
    products,
    categories,
    subCategories,
    manufacturers,
    spinning,
    categoryId,
    manufacturerId,
    setSelectedCategory,
    setSelectedManufacturer,
    setSelectedPrices,
    setSearchedText,
    setInStock,
    setByOrder,
    setSpinning,
    selectedPrices,
  } = FetchProducts();

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    setSearchedText("");
    history.push({
      pathname: "/result",
      search:
        params.get("manufacturer") === null
          ? `?category=${value}`
          : `?category=${value}&manufacturer=${params.get("manufacturer")}`,
    });
  };

  const handleManufacturerChange = (value) => {
    setSelectedManufacturer(value);
    setSelectedCategory(0);
    setSearchedText("");
    params.delete("manufacturer");
    history.push({
      pathname: "/result",
      search:
      params.get("category") === null
        ? `?manufacturer=${value}`
        : `?category=${0}&manufacturer=${value}`,
      });
      window.location.reload();
  };

  const handleMaximumPriceChange = (value) => {
    setSelectedPrices((selectedPrices) => ({
      ...selectedPrices,
      maximum: Number(value),
    }));
    setSearchedText("");
  };

  const handleMinimumPriceChange = (value) => {
    setSelectedPrices((selectedPrices) => ({
      ...selectedPrices,
      minimum: Number(value),
    }));
    setSearchedText("");
  };

  const handleInStockBoxChange = (e) => {
    setInStock(e.target.checked);
  };

  const handleByOrderBoxChange = (e) => {
    setByOrder(e.target.checked);
  };

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <>
      <HeaderMobile
        from="ResultPage"
        cartCount={cartCount}
        setCartCount={setCartCount}
      />
      <NavBar
        from="ResultPage"
        cartCount={cartCount}
        setCartCount={setCartCount}
      />
      <Layout>
        <SEO
          pageProps={{
            title: "Edelweiss Group Market",
            thumbnail: "",
            url: window.location,
          }}
        />
        <Content
          style={{ margin: "24px 24px", maxWidth: "100%", minHeight: "100vh" }}
        >
          <Row type="flex" justify="center" gutter={32} align="top">
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Card
                bordered={false}
                title={t("Result.Filter")}
                style={{ width: "100%", marginBottom: 32 }}
              >
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 48, color: "#2bbef9" }}
                      spin
                    />
                  }
                  spinning={spinning}
                >
                  <Form
                    initialValues={{
                      categoryId:
                        categoryId !== null ? parseInt(categoryId) : 0,
                      manufacturerId:
                        manufacturerId !== null ? parseInt(manufacturerId) : 0,
                    }}
                    layout="vertical"
                    {...layout}
                  >
                    <Form.Item label={t("Result.Category")} name="categoryId">
                      <Select name="categoryId" onChange={handleCategoryChange}>
                        <Option value={0}>{t("Global.All")}</Option>
                        {categories
                          .filter((x) => x.parentId === 0)
                          .map((cat) =>
                            cat.parentId === 0 &&
                            subCategories.some((x) => x.parentId === cat.id) ? (
                              <OptGroup
                                key={cat.id}
                                label={CategoryName(cat, i18n.language)}
                              >
                                <Option value={cat.id} key={cat.id}>
                                  {t("Global.All")} {" - "}
                                  {CategoryName(cat, i18n.language)}
                                </Option>
                                {subCategories
                                  .filter((x) => x.parentId === cat.id)
                                  .map((subCat) => (
                                    //todo add keys
                                    <React.Fragment key={subCat.id}>
                                    <Option value={subCat.id} key={subCat.id}>
                                        <span>-- </span>
                                        {CategoryName(subCat, i18n.language)}
                                      </Option>
                                      {subCategories
                                        .filter((x) => x.parentId === subCat.id)
                                        .map((child) => (
                                          <Option
                                            value={child.id}
                                            key={child.id}
                                          >
                                            <span>---- </span>
                                            {CategoryName(child, i18n.language)}
                                          </Option>
                                        ))}
                                  </React.Fragment>
                                  ))}
                              </OptGroup>
                            ) : (
                              <Option value={cat.id} key={cat.id}>
                                {CategoryName(cat, i18n.language)}
                              </Option>
                            )
                          )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={t("Result.Manufacturer")}
                      name="manufacturerId"
                    >
                      <Select
                        showSearch
                        onChange={handleManufacturerChange}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value={0}>{t("Global.All")}</Option>
                        {manufacturers.map((manufacturer) => (
                          <Option value={manufacturer.id} key={manufacturer.id}>
                            {ManufacturerName(manufacturer, i18n.language)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {ValidateToken() && (
                      <Form.Item label={t("Result.Price")} name="minimumPrice">
                        <InputGroup compact>
                          <InputNumber
                            min={0}
                            style={{ width: "50%", textAlign: "center" }}
                            placeholder={t("Result.Minimum")}
                            onChange={handleMinimumPriceChange}
                            value={
                              isNaN(selectedPrices.minimum)
                                ? 0
                                : selectedPrices.minimum
                            }
                          />
                          <InputNumber
                            min={0}
                            style={{
                              width: "50%",
                              textAlign: "center",
                              borderLeft: 0,
                            }}
                            placeholder={t("Result.Maximum")}
                            onChange={handleMaximumPriceChange}
                            value={
                              isNaN(selectedPrices.maximum)
                                ? 0
                                : selectedPrices.maximum
                            }
                          />
                        </InputGroup>
                      </Form.Item>
                    )}
                    <Row type="flex" justify="start" align="middle">
                      <Form.Item>
                        <Checkbox
                          defaultChecked={
                            params.get("inStock") !== null
                              ? JSON.parse(params.get("inStock"))
                              : true
                          }
                          onChange={handleInStockBoxChange}
                        >
                          {t("ProductPage.InStock")}
                        </Checkbox>
                        <Checkbox
                          style={{ textAlign: "center", margin: 0 }}
                          defaultChecked={
                            params.get("byOrder") !== null
                              ? JSON.parse(params.get("byOrder"))
                              : true
                          }
                          onChange={handleByOrderBoxChange}
                        >
                          {t("ProductPage.ByOrder")}
                        </Checkbox>
                      </Form.Item>
                    </Row>
                  </Form>
                </Spin>
              </Card>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 18 }} className="results">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 48, color: "#2bbef9" }}
                    spin
                  />
                }
                spinning={spinning}
              >
                <Row type="flex" justify="start" gutter={16} align="top">
                  {products.length > 0 ? (
                    <Products
                      products={products}
                      categories={categories}
                      cartData={cartData}
                      setCartData={setCartData}
                      cartCount={cartCount}
                      setCartCount={setCartCount}
                      setSpinning={setSpinning}
                      fromPage="Result"
                      productsIds={products.map((x) => x.id)}
                    />
                  ) : (
                    <p>{t("Result.NotFound")}</p>
                  )}
                </Row>
              </Spin>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default Result;
