import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import api from "./../Api";
import { Typography, Row, Layout, Spin } from "antd";
import NavBar from "../Components/NavBar";
import HeaderMobile from "./../Components/HeaderMobile";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import SEO from "../Components/SEO";
import { EditorText, PostOrPageTitle } from "../Common";
import { LoadingOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const BlogSingle = () => {
  const [blog, setBlog] = useState({});
  const [cartCount, setCartCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  const match = useRouteMatch("/blog/:slug");
  const history = useHistory();

  useEffect(() => {
    const fetchBlogPosts = async () => {
      await api
        .blog(match.params.slug)
        .get()
        .then((response) =>
          response.data.length === 0
            ? history.push("/404")
            : setBlog(response.data[0])
        );
      setLoading(false);
    };
    const getCartData = async () => {
      await api
        .cart()
        .get()
        .then((res) => {
          setCartCount(res.data.length);
        })
        .catch((err) => {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            console.log(err);
          }
        });
    };
    window.scrollTo(0, 0);
    fetchBlogPosts();
    getCartData();
  }, [history, match.params.slug]);

  return (
    <Spin
      indicator={
        <LoadingOutlined style={{ fontSize: 24, color: "#2bbef9" }} spin />
      }
      spinning={loading}
    >
      <HeaderMobile cartCount={cartCount} setCartCount={setCartCount} />
      <NavBar
        from="BlogSingle"
        cartCount={cartCount}
        setCartCount={setCartCount}
      />
      <Layout>
        <SEO
          pageProps={{
            title: PostOrPageTitle(blog, i18n.language),
            thumbnail: blog.imageUrl ? blog.imageUrl : "",
            url: window.location,
          }}
        />
        <Content
          style={{ margin: "24px 24px", maxWidth: "100%", minHeight: "100vh" }}
        >
          <Row type="flex" justify="center" gutter={32} align="middle">
            {blog.imageUrl ? (
              <div
                style={{
                  backgroundImage: `url(${blog.imageUrl})`,
                  backgroundAttachment: blog.imageIsFixed ? "fixed" : "scroll",
                  backgroundPosition: blog.imageIsFixed ? "bottom" : "center",
                }}
                className="blog-header"
              >
                <Title className="blog-title">
                  {PostOrPageTitle(blog, i18n.language)}
                </Title>
              </div>
            ) : (
              ""
            )}
          </Row>
          <Row type="flex" justify="start" align="middle">
            <Paragraph className="ck-content">
              {ReactHtmlParser(EditorText(blog, i18n.language).editorText)}
            </Paragraph>
          </Row>
        </Content>
      </Layout>
    </Spin>
  );
};

export default BlogSingle;
